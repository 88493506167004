import React, { useEffect, useState } from "react";
import {Tag, Table, Button} from "antd";
import {CaretUpOutlined,CaretDownOutlined} from "@ant-design/icons";
import { ExportOutlined } from "@ant-design/icons";

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";


const BreakdownByCondition = (props) => {
    const { t, statsData, requestData, detailsBoxTitle } = props; // eslint-disable-line
    const [groupStatsData,setGroupStatsData] = useState([]);
    const [visibleCols, setVisibleCols] = useState([]);

    useEffect(() => {
        const data = [];

        if (Object.keys(statsData.conditioncode).length) {
            setVisibleCols(Object.keys(statsData.conditioncode[0]));
        }

        Object.keys(statsData.conditioncode).forEach((item) => {
           // const k = parseFloat(statsData.conditioncode[item]?.dispute_condition_code)>0 ? statsData.conditioncode[item]?.dispute_condition_code : 0;
           //to keep 0 and empty as it is removing parsefloat
            const k = statsData.conditioncode[item]?.dispute_condition_code;
            const d = {
                key: item,
                condition_code: statsData.conditioncode[item].dispute_condition_code,
                alert_type: statsData.conditioncode[item]?.alert_type,
                issuer: statsData.conditioncode[item]?.issuer,
                providername: statsData.conditioncode[item]?.providername,
                card_type: statsData.conditioncode[item]?.card_type,
                count: statsData.conditioncode[item].count
            };
            if (k in data) {
                data[k].total = data[k].total + parseInt(d.count);
                data[k].innerrow.push(d);
            } else {
                data[k] = [];
                data[k].key = item;
                data[k].condition_code = statsData.conditioncode[item].dispute_condition_code;
                data[k].reason = statsData.conditioncode[item].reason;
                data[k].alert_type = statsData.conditioncode[item]?.alert_type;
                data[k].providername = statsData.conditioncode[item]?.providername;
                data[k].card_type = statsData.conditioncode[item]?.card_type;
                data[k].issuer = statsData.conditioncode[item]?.issuer;
                data[k].count = statsData.conditioncode[item].count;
                data[k].parent = true;
                data[k].total = parseInt(d.count);
                data[k].innerrow = [d];
            }
        });
        //To preserve the correct order, extract keys, sort them manually, and map values correctly
        setGroupStatsData(Object.values(data).sort((a, b) => a.condition_code.localeCompare(b.condition_code)));
    }, [statsData]);

    const columns = [
        {
          title: t("contents.alerts.dispute_condition_code"),
          key: 'condition_code',
          width: '70%',
          render: (record) => (record.condition_code!=='' ?  record.condition_code + (record.reason!=='' ? ` (`+record.reason+`)` : ``) : <Tag color="error">Empty</Tag>),
          sorter: {
            compare: (a, b) => a.condition_code.localeCompare(b.condition_code),
          }
        },
        {
          title: "Total Count",
          dataIndex: 'total',
          key: 'total',
          width: '30%',
          sorter: {
            compare: (a, b) => a.total - b.total,
          }
        },
      ];

   
    const customExpandIcon = (props) => {
        if (props.expanded) {
            return <button className="collapse" style={{ color: 'black',backgroundColor: 'transparent',
            border: 'none',textDecoration: 'underline',cursor: 'pointer'}} onClick={e => {
                props.onExpand(props.record, e);
            }}><CaretUpOutlined /></button>
        } else {
            return <button className="expand" style={{ color: 'black',backgroundColor: 'transparent',
            border: 'none',textDecoration: 'underline',cursor: 'pointer'}} onClick={e => {
                props.onExpand(props.record, e);
            }}><CaretDownOutlined /></button>
        }
    };

    const hiddenColumn = (col) => {
        return (visibleCols.indexOf(col) === -1);
    }

    const innerTable = (innerData) => (
        <table id="data-table" style={{ tableLayout: "auto", width: "100%" }}>
            <thead className="ant-table-thead">
                <tr>
                    <th className="ant-table-cell" width="20%" hidden = {hiddenColumn('alert_type')}>
                        {t("contents.alerts.alert_type")}
                    </th>
                    <th className="ant-table-cell" width="20%" hidden = {hiddenColumn('issuer')}>
                        {t("contents.alerts.issuername")}
                    </th>
                    <th className="ant-table-cell" width="20%" hidden = {hiddenColumn('providername')}>
                    {t("contents.alerts.providername")}
                    </th>
                    <th className="ant-table-cell" width="20%" hidden = {hiddenColumn('card_type')}>
                    {t("contents.alerts.cardtype")}
                    </th>
                    <th className="ant-table-cell" width="20%">
                        <div className="text-center">{t("contents.alerts.count")}</div>
                    </th>
                </tr>
            </thead>
            <tbody className="ant-table-tbody">
                {innerData
                    ? Object.keys(innerData).map((item) => (
                        // <tr><td>Key: {JSON.stringify(item)}</td><td>Item: {JSON.stringify(innerData[item])}</td></tr>
                            <tr className="ant-table-row ant-table-row-level-0" key={item}>
                                <td className="ant-table-cell" align="left" hidden = {hiddenColumn('alert_type')}>
                                    {(innerData[item]?.alert_type ? innerData[item]?.alert_type : <Tag color="error">Empty</Tag>)}
                                </td>
                                <td className="ant-table-cell" align="left" hidden = {hiddenColumn('issuer')}>
                                    {(innerData[item]?.issuer ? innerData[item]?.issuer : <Tag color="error">Empty</Tag>)}
                                </td>
                                <td className="ant-table-cell" align="left" hidden = {hiddenColumn('providername')}>
                                    {(innerData[item]?.providername ? innerData[item]?.providername : <Tag color="error">Empty</Tag>)}
                                </td>
                                <td className="ant-table-cell" align="left" hidden = {hiddenColumn('card_type')}>
                                    {(innerData[item]?.card_type ? innerData[item]?.card_type : <Tag color="error">Empty</Tag>)}
                                </td>
                                <td className="ant-table-cell"  align="center">{innerData[item]?.count}</td>
                            </tr>
                        ))
                    : null}
            </tbody>
        </table>
    );

    const exportNestedDataToExcel = (data, fileName = "alert_statistics_by_dispute_condition_code.xlsx") => {
       
        const formattedData = [];

        // Header row
        formattedData.push(["Dispute Condition Code", "Total Count", "", "", ""]);
      
        data.forEach((record) => {
             // Add main row (ID & Name)
          let   conditioncode = record.condition_code !=='' ?  record.condition_code + (record.reason!=='' ? ` (`+record.reason+`)` : ``) : 'Empty';
        formattedData.push([conditioncode, record.total, "", "", ""]);
        formattedData.push(["Alert Type", "Issuer Name", "Provider", "Card Type", "Count"]);
          record.innerrow.forEach((type) => {
            formattedData.push([type.alert_type,type.issuer, type.providername,type.card_type,type.count]);
          });
        });
     

        const ws = XLSX.utils.aoa_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      
        // Adjust column widths for better readability
        ws["!cols"] = [
            { wch: 50 }, // condition code column width
            { wch: 20 }, // issuer column width
            { wch: 10 }, // provider column width
            { wch: 10 }, // card type column width
            { wch: 10 }, // count column width
        ];
      
        // Convert to Blob and Save
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
      
        saveAs(blob, fileName);
      };

     // Handle sorting changes
  const handleTableChange = (pagination, filters, sorter) => {
   
    if(sorter.order){
        if(sorter.columnKey === 'condition_code'){
            if(sorter.order === 'ascend' ){
                groupStatsData.sort((a, b) => a.condition_code.localeCompare(b.condition_code));
            }else
            {
                groupStatsData.sort((a, b) => b.condition_code.localeCompare(a.condition_code));
            }
        }else{
            if(sorter.order === 'ascend' ){
                groupStatsData.sort((a, b) => a.total - b.total);
            }else{
                groupStatsData.sort((a, b) => b.total - a.total);
            }
        }
    }
    
  };

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t(`contents.alerts.`+(['reason','conditioncode'].indexOf(detailsBoxTitle)!== -1 ? 'dispute_condition_code' : detailsBoxTitle))}</h3>
             <Button key="btn_export" type="link"  disabled={groupStatsData.length === 0} onClick={() => exportNestedDataToExcel(groupStatsData)}>
                            <div className="d-flex align-items-center">
                                <ExportOutlined style={{ fontSize: "18px" }} /> <div style={{ marginLeft: "4px" }}>Export</div>
                            </div>
                        </Button>
           
        </div>
    );

    

    const getDetails = () => (
        <div className="table-v3">
            <div className="ant-table ant-table-small">
                {/* <div className="ant-table-title">
                    <div className="custom-table--header d-flex justify-content-between align-items-center">
                        <h3 className="table-title">{t(`contents.alerts.`+(['reason','conditioncode'].indexOf(detailsBoxTitle)!=-1 ? 'dispute_condition_code' : detailsBoxTitle))}</h3>
                    </div>
                </div> */}
                <div className="ant-table-container">
                    <div className="ant-table-content">
                        <Table id="grp_by_condition_code" style={{ tableLayout: "auto", width: "100%" }}
                            columns={columns}
                            title={() => getHeaderTable()}
                            dataSource={groupStatsData}
                            expandIcon={(props) => customExpandIcon(props)}
                            expandRowByClick={true}
                            expandedRowRender={(record) => innerTable(record.innerrow)}
                            pagination={false} 
                            onChange={handleTableChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return getDetails();
};

export default BreakdownByCondition;
