module.exports = {
    jp: {
        translation: {
            login: {
                email: "メールアドレス",
                username: "ユーザー名",
                password: "パスワード",
                login: "ログイン",
                submit: "決定",
                continue: "継続する",
                forgotpassword: "パスワードをお忘れですか？",
                backtologin: "前の画面に戻る",
                verification: {
                    verify: "認証",
                    gotit: "了解！",
                    verificationcode: "検証コード",
                    entercode: "Google認証コード",
                    emailcode: "このユーザーアカウントの登録済みメールアドレスに確認コードを送信しました。 メールを確認し、以下のコードを入力してください。",
                    howtosetup: "2段階認証の設定方法",
                    howtosetup_title: "2段階認証のセットアップ",
                    howtosetup_text: "ログインするにはGoogle認証アプリまたはFreeOTPのセットアップが必要です。",
                    noqrcode_title: "オーセンティケーターアプリケーションをセットアップする",
                    noqrcode_text: "管理者に連絡してください。",
                    cantscan_click: "スキャンできませんか？ ここをクリック",
                    copied: "コピー",
                    copy_secretkey: "秘密鍵をコピーする",
                    scan_qrcode_below: "以下のQRコードをスキャンし、完了したら送信してください",
                },
                required: {
                    email: "メールアドレスを入力してください",
                    username: "ユーザー名を入力してください",
                    password: "パスワードを入力してください",
                },
                dialogs: {
                    login_error: "ユーザー名またはパスワードが一致しません",
                    verify_error: "認証コードが一致しません",
                    login_success: "ログイン成功",
                    reset_password: "アカウントの電子メールに送信するパスワードのリセットを要求します",
                    password_changed: "パスワードが更新されました",
                    groups_changed: "グループが更新されました",
                    profile_changed: "プロファイルが更新されました",
                    catch: "何かがうまくいかなかった！",
                },
                session: {
                    title: "セッションの有効期限が切れます",
                    desc_1: "ログアウトします",
                    desc_2: "秒。",
                    desc_3: "[OK]をクリックして続行します。",
                    expired: "セッションの有効期限が切れ",
                },
            },
            resetpassword: {
                title: "パスワードを再設定する",
            },
            header: {
                menu: {
                    profile: "基本情報",
                    logout: {
                        title: "ログアウト",
                        question: "ログアウトしてもよろしいですか？",
                        yes: "はい",
                        no: "いいえ",
                    },
                },
                clock: "システム時刻",
            },
            sidebar: {
                dashboard: "ダッシュボード",
                merchants: "加盟店",
                merchant: "加盟店",
                txfind_bincaids: "TxFind BIN/CAID",
                bincaids: "BIN/CAID",
                descriptors: "決済アカウント名",
                descriptor: "決済アカウント名",
                alertdescriptors: "決済アカウント名",
                bincaiddescriptors: "BIN/CAID",
                payments: "支払い",
                transactions: "トランザクション",
                fraudchecks: "不正チェック",
                refunds: "払い戻し",
                "3dsecure": "3DS",
                "3dsecure_microservice": "3Dセキュア",
                alerts: "アラート",
                alertstats: "Alerts Statistics",
                rdractivity: "RDR アクティビティ",
                messages: "メッセージ",
                userguide: "ユーザーガイド",
                chargebacks: "チャージバック",
                alertsforreview: "レビューのためのアラート",
                order_insight: "TxFind",
                carts: "カート",
                lookup: "ルックアップ履歴",
                providers: "プロバイダー",
                merchant_providers: "マーチャントプロバイダー",
                statistics: "統計",
                "3dsstats": "3Dセキュア統計",
                alert: "アラートの詳細",
                chargeback: "チャージバック",
                chargeback_merchants: "チャージバック加盟店",
                chargeback_providers: "チャージバックプロバイダー",
                chargeback_merchant_providers: "チャージバック加盟店プロバイダー",
                oi_merchants: "TxFindマーチャント",
                oi_carts: "TxFindカート",
                oi_carts_lookup: "TxFindルックアップ履歴",
                oi_cart: "TxFindカートの詳細",
                txfind_merchants: "TxFindマーチャント",
                txfind_carts: "TxFindカート",
                txfind_carts_lookup: "TxFindルックアップ履歴",
                txfind_ce_lookup: "TxFind CE 見上げる",
                txfind_cart: "TxFindカートの詳細",
                profile: "プロフィール",
                // virtual_terminal: "仮想端末",
                alertdescriptor:"Alert Descriptor",
                binmid:"BIN / MID",
                "3dsbinmid":"BIN / MID",
                chargeback_merchant: "Chargeback Merchant",
                alertproviders:"Alert Providers",
                merchantalertproviders:"Merchant Alert Providers",
                chargeback_alertprovider_logins:"Merchant Alert Providers",
                chargeback_alertproviders:"Alert Providers",
                balance:"バランス",
                paymentlink: '支払いリンク',
            },
            contents: {
                dashboard: {
                    total: "合計",
                    today: "本日",
                    alerts: "アラート",
                    alert_today: "今日アラート",
                    alerts_today: "今日のアラート",
                    alertsfor: "のアラート",
                    type: "アラート種類",
                    title: "ダッシュボード",
                    "3dsecure": "3DS",
                    "3dsecure_merchant": '商人',
                    "3ds_transaction": '3DS',
                    fraudchecks: "不正チェック",
                    transactions: "トランザクション",
                    alerts_chart2: "アラート - 1日のアラート数",
                    transaction_count: "トランザクション件数",
                    total_revenue_collected: "決済額合計",
                    total_counts_of_3dsecure: "3DS件数",
                    total_counts_of_fraudcheck: "不正検知数",
                    avg_ticket_value_of_transaction: "決済平均単価",
                    avg_ticket_value_of_fraudcheck: "不正平均単価",
                    expiring: "Expiring Today",
                    action_required: "Action required",
                    wip: "WIP",
                    alert_info: "Alert Info",
                    transaction_info: "Transaction Info",
                },
                days: {
                    sun: "日曜日",
                    mon: "月曜",
                    tue: "火曜日",
                    wed: "水曜日",
                    thu: "木曜日",
                    fri: "金曜日",
                    sat: "土曜日",
                },
                months: {
                    january: "1月",
                    february: "2月",
                    march: "3月",
                    april: "4月",
                    may: "5月",
                    june: "6月",
                    july: "7月",
                    august: "8月",
                    september: "9月",
                    october: "10月",
                    november: "11月",
                    december: "12月",
                },
                retailers: {
                    title: "Retailers",
                },
                sites: {
                    title: "Sites",
                },
                merchants: {
                    title: "加盟店",
                    merchant: "Merchant",
                    details: "Details",
                    retailer_details: "Retailer Details",
                    payment: "Payment",
                    "3ds": "3DS",
                    alerts: "Alerts",
                    settings: "Settings",
                    access_records: "Access Records",
                    bincaid: "Bincaid",
                    descriptor:"Descriptors",
                    balance:"加盟店残高",
                    form: {
                        sid: "SID",
                        name: "Name",
                        view: "View",
                        rcode: "Rcode",
                        theme: "Theme",
                        status: "Status",
                        active: "Active",
                        remarks: "Remarks",
                        generate: "Generate",
                        inactive: "Inactive",
                        reseller: "Reseller",
                        description: "Description",
                        create_reseller: "Create Reseller",
                        txfind_merchant_id: "TxFind Merchant ID",
                        generate_new_rcode: "Generate New Rcode",
                        please_select_retailer: "Please select retailer",
                        please_select_site: "Please select site",
                        please_select: "Please select",
                        site_url: "Site URL(http address)",
                        site_rcode: "Site Rcode",
                        associated_users: "Associated Users",
                        stop_send_email: "Stop Emails from SID",
                        cbreportemail: "Email Address For Chargebacks Report",
                        available_balance_for_withdraw: "Available Balance For Withdraw",
                        category: "Category",
                        support_group: "Support Group",
                        fraud_template: "Fraud Template",
                        skip_ccv: "Skip CCV Check",
                        bin_currency: "Use BIN to choose pay currency",
                        settlement_currency: "Settlement Currency",
                        allow_rebilling: "Allow Rebilling",
                        proceedeveniffail: "Continue Process on fail",
                        margin_percentage: "Settlement Margin Percentage (%)",
                        alert_on_next_approved_tranaction: "Alert on next approved transaction",
                        to_address: "To Address",
                        allow_refund: "Allow Refund",
                        allow_refund_time: "Unlimited Refund Time",
                        emaildomain: "Unlimited Refund Time Not Active Sendgrid authenticated email domain(receipt email, support email)",
                        shippingntracking: "Shipping & Tracking Enabled",
                        nerver: "Never",
                        perday: "per day",
                        unlimited: "Unlimited",
                        notactive: "Not Active",
                        days: "Day(s)",
                        app: "App",
                        brw: "Browser",
                        "3ri": "3DS Requestor Initiated (3RI)",
                        yes: "Yes",
                        no: "No",
                        payment_transaction: "Payment transaction",
                        recurring_transaction: "Recurring transaction",
                        instalment_transaction: "Instalment transaction",
                        add_card: "Add card",
                        maintain_card: "Maintain card",
                        cardholder_verification: "Cardholder verification as part of EMV token ID & V",
                        not_applicable: "Not Applicable",
                        credit: "Credit",
                        debit: "Debit",
                        gs: "Goods/Service Purchase",
                        ca: "Check Acceptance",
                        af: "Account Funding",
                        qt: "Quasi-Cash Transaction",
                        pal: "Prepaid Activation and Load",
                        paay: "Paay",
                        gpay: "Gpay",
                        hitrust: "HiTrust",
                        verbose: "Verbose mode on",
                        client_mode: "Client mode",
                        enable_result: "Enable result",
                        disable_challenge: "Disable challenge",
                        enable3ds1_frictionless: "Enable 3ds1 frictionless",
                        enable3DS1_only: "Enable 3DS 1.0 only",
                        requestor_name: "Requestor name",
                        requestor_url: "Requestor URL",
                        authentication_indicator: "3DS Requestor Authentication Indicator",
                        challenge_indicator: "3DS Requestor Challenge Indicator",
                        acct_type: "Account Type",
                        acquirer_bin: "Acquiring Bank BIN",
                        acquirer_mid: "Acquirer Merchant ID",
                        master_acquirer_bin: "Mastercard Acquiring Bank BIN",
                        master_acquirer_mid: "Mastercard Acquirer Merchant ID",
                        jcb_acquirer_bin: "JCB Acquiring Bank BIN",
                        jcb_acquirer_mid: "JCB Acquirer Merchant ID",
                        cup_acquirer_bin: "CUP Acquiring Bank BIN",
                        cup_acquirer_mid: "CUP Acquirer Merchant ID",
                        amex_acquirer_bin: "AMEX Acquiring Bank BIN",
                        amex_acquirer_mid: "AMEX Acquirer Merchant ID",
                        acquirer_mid_pwd: "Acquirer Merchant ID Password",
                        merchant_category_code: "Merchant Category Code",
                        merchant_country_code: "Merchant Country Code",
                        merchant_name: "Merchant Name",
                        message_category: "Message Category",
                        purchase_currency: "Purchase Currency",
                        trans_type: "Transaction Type",
                        skip3d2: "Skip 3D2.0 (Overwritten by gateway setting)",
                        three_ds_provider: "3Ds Provider",
                        threedsprotocolversion: "3DS protocol version",
                        paayauth3d2: "Paay APIkey | Secret",
                        gpay_merchant_token: "Gpay Merchant Token",
                        gpay_merchant_id: "Gpay Merchant ID",
                        gpay_base_url: "Gpay URL",
                        hitrust_account_id: "HiTrust Account ID",
                        hitrust_password: "HiTrust Password",
                        hitrust_base_url: "HiTrust Base Url",
                        hitrust_mastercard_prefix: "HiTrust Mastercard Requestor Prefix",
                        alertapiurl: "Alert api url",
                        descriptorid: "Descriptor ID",
                        username: "Username",
                        token: "Token",
                        default_dispute_action: "Default Dispute Action",
                        alert_triggeremail: "Email for alert?",
                        alert_email: "Email for alert notification",
                        shieldrdr_review_email: "Email for express resolve review notification",
                        statusupdate_url: "Alert Status Update url",
                        match: "Match only",
                        match_and_refund: "Match and refund",
                        enrolled_services:"登録されたサービス"
                    },
                },
                descriptors: {
                    title: "決済アカウント名",
                    tabs: {
                        expressresolve: "紛争情報",
                        table:{
                            date: "日付",
                        }
                    },
                },
                bincaids: {
                    title: "BIN/CAID",
                    view_bincaid: "View BIN/CAID",
                    details: "Details",
                    enrollment_history: "Enrollment History",
                    ruleset_history:"ルールセットの履歴",
                    form: {
                        bin: "Bin",
                        caid: "Caid",
                        mcc: "MCC",
                        tier: "ティア",
                        vrol_profile: "VROL Profile",
                        descriptor: "Descriptor",
                        request_date: "Requested Date",
                        update_date: "Updated Date",
                        status: "Status",
                        status_reason: "Status Reason",
                        reference_no: "Reference Number",
                    }
                },
                transactions: {
                    title: "トランザクション",
                    details: "取引の詳細",
                    summmary:"取引概要",
                    pending_refunds:"保留中の払い戻し",
                    approved_count: "承認数",
                    approved_sum:"承認された合計",
                    declined_count:"拒否された数",
                    declined_sum:"辞退額",
                    total:"合計",
                    action:"アクション",
                    tabs: {
                        tx_history: "TXの歴史",
                        tx_details: "TXの詳細",
                        payee_details: "受取人の詳細",
                        tx_items: "TXアイテム",
                        fraudcheck_details: "詐欺チェックの詳細",
                        communication_details: "コミュニケーションの詳細",
                        tracking_details: "追跡の詳細",
                    },
                    form: {
                        card_details: "カードの詳細",
                        settlement_details: "決済の詳細",
                        cart_and_processing_details: "カートと処理の詳細",
                        user_details: "ユーザーの詳細",
                        shipping_details: "配送の詳細",
                        other: "他の",
                        fraudcheck_details: "詐欺チェックの詳細",
                        communication_details: "コミュニケーションの詳細",
                        tracking_details: "追跡の詳細",
                        no_fraudcheck_information: "この取引に関する詐欺チェック情報はありません。",
                        no_communication_information: "この取引に関する通信情報はありません。",
                        no_tracking_details: "この取引の追跡の詳細はありません。",
                        card_type: "カードの種類",
                        card_name: "カード名",
                        card_no: "カード番号",
                        card_expiry: "カードの有効期限",
                        start_date: "開始日",
                        email_address: "電子メールアドレス",
                        ip_address: "IP地址",
                        auth_code: "認証コード",
                        account_type: "口座の種類",
                        account_name: "アカウント名",
                        account_no: "口座番号",
                        routing_no: "銀行コード",
                        purse_no: "財布番号",
                        arn: "アクワイアラレファレンスナンバー",
                        settlement_amount: "グロス決済額",
                        reserve: "予約",
                        fees: "料金",
                        sitereceive: "商人に支払う",
                        cartmargin: "カートマージン",
                        margin: "マージン",
                        currbalance: "経常収支",
                        origtotal: "合計金額",
                        origpurch: "支払金額",
                        origship: "运费金额",
                        origtax: "税额",
                        origcoupon: "クーポン金額",
                        processedamount: "処理量",
                        firstname: "ファーストネーム",
                        lastname: "苗字",
                        phoneno: "電話番号",
                        mobileno: "携帯電話番号",
                        address: "住所",
                        suburb: "都市/郊外",
                        state: "州",
                        postcode: "郵便番号",
                        country: "国",
                        tid: "マーチャントリファレンス (TID)",
                        wid: "オペレーターID (WID)",
                        info: "追加情報",
                        ipay: "iPay情報",
                        ref1: "参照 1",
                        ref2: "参照 2",
                        ref3: "参照 3",
                        ref4: "参照 4",
                        affiliateid: "アフィリエイトID",
                        threat_level_assessment: "脅威レベルの評価",
                        rule: "ルール",
                        error: "エラー",
                        response: "応答",
                        generates: "生成します",
                    },
                },
                fraudchecks: { title: "不正チェック" },
                refunds: { title: "払い戻し" },
                "3dsecure": {
                    title: "3Dセキュア-トランザクション",
                    details: "3Dセキュア-詳細",
                    view_binmid: "View BIN / MID",
                    imports_binmid: "Import BIN / MID",
                    tabs: {
                        "3DS_details": "3DSの詳細",
                    },
                    binmid: {
                        title: "BIN / MID",
                        binmidlist: "BIN / MID List",
                        new_binmid: "New BinMid",
                        added: "BINMID successfully added",
                        updated: "BINMID has been Updated"
                    },
                    merchant: {
                        title: '3DS加盟店',
                        merchantlist: "3DS加盟店一覧",
                        new_merchant: '新しい 3DS 販売者',
                    },
                    form: {
                        "3ds_authorization_details": "3DS認証の詳細",
                        "3ds_log_details": "3DSログの詳細",
                        "3DS20": "3DS2.0",
                        acctNumber: "カード番号",
                        purchaseAmount: "額",
                        threeDSIntegratorOid: "ThreeDSインテグレーターOid",
                        threeDSRequestorOrderID: "ThreeDSリクエスターOrderID",
                        threeDSRequestorData: "ThreeDSリクエスターデータ",
                        threeDSRequestorID: "ThreeDSリクエスターID",
                        threeDSServerTransID: "ThreeDSサーバートランスID",
                        acsTransID: "ACSトランスID",
                        dsTransID: "DSトランスID",
                        transStatus: "トランスステータス",
                        transStatusReason: "トランスステータスの理由",
                        authenticationValue: "認証値",
                        authenticationAlgorithm: "認証アルゴリズム",
                        eci: "ECI",
                        version: "バージョン",
                        errCode: "エラーコード",
                        errMsg: "エラーメッセージ",
                        shield_threeds_ref_diaplay: "TxShield 3DSリファレンス",
                        shield_threeds_merchant_ref_display: "マーチャントリファレンス",
                        shield_threeds_provider_ref_display: "3DSプロバイダーリファレンス",
                        shield_threeds_ptxid_display: "TxShield 3DS PTXID",
                        shield_threeds_pay_ptxid_display: "TxShield 支払い PTXID",
                        threeDSShieldInitLog: "ログデータの送信",
                        threeDSShieldNotifyLog: "ログデータを返す",
                        binmidID: "BINMID ID",
                        bin: "BIN",
                        mid: "MID",
                        mcc: "MCC",
                        card_type: "Card Type",
                        status: "Status",
                        date_added: "Date Added",
                        site_name: "Site",
                        merchant_name: "商人",
                        cancel: "Cancel",
                        submit: "Submit",
                        submitting: "Submitting",
                        please_select: "Please select"
                    },
                },
                _3dsecure_stats: {
                    title: "3Dセキュア統計",
                    please_select_merchant: "販売者を選択してください",
                    filter_by_site: "サイトでフィルタリング",
                    filter_by_status: "ステータスでフィルタリング",
                    pass: "パス",
                    fail_others: "失敗（その他）",
                    no_data: "データなし",
                    today: "今日",
                    last7DaysPerHour: "過去7日間/時間",
                    last7DaysPerDay: "1日あたり過去7日間",
                    last30DaysPerDay: "1日あたり過去30日間",
                },
                _3dsecure_merchant: {
                    new_merchant: '新しい 3DS 販売者',
                    edit_merchant: '3DS 販売者の編集',
                    form: {
                        merchant_detail: "販売者の詳細",
                        site_detail: "サイト詳細",
                        "3ds_setting": "3DSの設定",
                        name: "名前",
                        description: "説明",
                        remarks: "備考",
                        contact: "接触",
                        status: '状態',
                        status_option_active: 'アクティブ',
                        status_option_deactivated: '無効化されました',
                        provider: 'プロバイダー',
                        site_currency: '通貨',
                        site_url: 'Url',
                        verbose_mode: '冗長モードがオン',
                        client_mode: 'クライアントモード',
                        client_mode_option_app: 'アプリ',
                        client_mode_option_brw: 'ブラウザ',
                        client_mode_option_3ri: '3DS リクエスター開始 (3RI)',
                        enable_result: '結果を有効にする',
                        enable_result_option_y: "はい",
                        enable_result_option_n: "いいえ",
                        disable_challenge: 'チャレンジを無効にする',
                        disable_challenge_option_y: "はい",
                        disable_challenge_option_n: "いいえ",
                        enable_3ds1_friction_less: "3DS 1.0 フリクションレスを有効にする",
                        enable_3ds1_friction_less_option_y: "はい",
                        enable_3ds1_friction_less_option_n: "いいえ",
                        enable_3ds1_only: "3DS 1.0のみを有効にする",
                        enable_3ds1_only_option_y: "はい",
                        enable_3ds1_only_option_n: "いいえ",
                        requestor_name: '依頼者名',
                        requestor_url: 'リクエスタの URL',
                        "3DS_requestor_authentication_indicator": "3DS リクエスタ認証インジケーター",
                        "3DS_requestor_authentication_indicator_option_payment_transaction": "支払い取引",
                        "3DS_requestor_authentication_indicator_option_recurring_transaction": "定期的なトランザクション",
                        "3DS_requestor_authentication_indicator_option_installment_transaction": "割賦取引",
                        "3DS_requestor_authentication_indicator_option_add_card": "カードを追加",
                        "3DS_requestor_authentication_indicator_option_maintain_card": "カードのメンテナンス",
                        "3DS_requestor_authentication_indicator_option_cardholder_verification": "EMV トークン ID&V の一部としてのカード所有者認証",
                        "3DS_requestor_challenge_indicator": "3DS リクエストチャレンジインジケーター",
                        "3DS_requestor_challenge_indicator_option_payment_transaction": "支払い取引",
                        "3DS_requestor_challenge_indicator_option_recurring_transaction": "定期的なトランザクション",
                        "3DS_requestor_challenge_indicator_option_installment_transaction": "割賦取引",
                        "3DS_requestor_challenge_indicator_option_add_card": "カードを追加",
                        acct_type: "アカウントの種類",
                        acct_type_option_not_applicable: "適用できない",
                        acct_type_option_credit: "クレジット",
                        acct_type_option_debit: "デビット",
                        visa_acquiring_bank_bin: "ビザ取得銀行のビン",
                        visa_acquirer_merchant_id: "ビザ取得者マーチャントID",
                        mastercard_acquiring_bank_bin: "Mastercard 取得銀行 BIN",
                        mastercard_acquirer_merchant_id: "Mastercard アクワイアラー マーチャント ID",
                        jcb_acquiring_bank_bin: "JCB アクワイアリング銀行 Bin",
                        jcb_acquirer_merchant_id: "JCB取得者加盟店ID",
                        cup_acquiring_bank_bin: "CUP 取得銀行 BIN",
                        cup_acquirer_merchant_id: "CUP取得者マーチャントID",
                        amex_acquiring_bank_bin: "AMEX 取得銀行の Bin",
                        amex_acquirer_merchant_id: "AMEX 取得者マーチャント ID",
                        acquirer_merchant_id_password: "取得者 加盟店ID パスワード",
                        merchant_category_code: "加盟店カテゴリーコード",
                        merchant_country_code: '販売者の国コード',
                        merchant_name: '販売者名',
                        message_category: 'メッセージカテゴリ',
                        message_category_option_pa: 'PA',
                        message_category_option_npa: 'NPA',
                        purchase_currency: '購入通貨',
                        transaction_type: '取引タイプ',
                        transaction_type_option_gs: '商品・サービスの購入',
                        transaction_type_option_ca: '受入確認',
                        transaction_type_option_af: 'アカウントの資金調達',
                        transaction_type_option_qt: '準現金取引',
                        transaction_type_option_pal: 'プリペイドのアクティベーションとロード',
                        "3ds_provider": '3DSプロバイダー',
                        "3ds_provider_option_hitrust": 'HiTrust',
                        "3ds_protocol_version": "3DSプロトコルバージョン",
                        hitrust_account_id: 'Hitrust アカウント ID',
                        hitrust_password: 'HiTrustパスワード',
                        hitrust_base_url: "HiTrust ベース URL",
                        hitrust_mastercard_requestor_prefix: 'HiTrust Mastercard リクエスタ プレフィックス'
                    }
                },
                messages: { title: "メッセージ" },
                chargebacks: {
                    title: "チャージバック",
                    chargeback: "チャージバック",
                    merchants: "加盟店",
                    providers: "プロバイダー",
                    merchantproviders: "マーチャントプロバイダー",
                    document_file: "ドキュメントファイル",
                    new_chargeback: "新しいチャージバック",
                    chargeback_import: "チャージバックインポート",
                    chargeback_details: "チャージバックの詳細",
                    create_merchant: "マーチャントを作成する",
                    create_provider: "プロバイダーの作成",
                    create_merchant_provider: "マーチャントプロバイダーを作成する",
                    view_merchant: "マーチャントを表示",
                    view_provider: "プロバイダーを表示",
                    new_merchant: "新しい加盟店",
                    new_provider: "新しいプロバイダー",
                    new_merchant_provider: "新しいマーチャントプロバイダー",
                    request_alert_credit: "アラートクレジットのリクエスト",
                    chargeback_documents: "チャージバックドキュメント",
                    alertproviders:"Alert Providers",
                    new_alertprovider: "New Alert Provider",
                    create_alertprovider: "Create Alert Provider",
                    view_alertprovider: "View Alert Provider",
                    new_merchant_alertprovider: "New Merchant Alert Provider",
                    create_merchant_alertprovider: "Create Merchant Alert Provider",
                    view_merchant_alertprovider: "View Merchant Alert Provider",
                    merchantalertproviders: "Merchant Alert Providers",
                    form: {
                        cancel: "キャンセル",
                        upload: "アップロード",
                        submit: "決定",
                        import: "インポート",
                        importing: "インポート",
                        uploading: "アップロード",
                        submitting: "提出する",
                        select_file: "ファイルを選ぶ",
                        file_name: "ファイル名",
                        csv_file: "CSVファイル",
                        merchant: "加盟店",
                        provider: "プロバイダー",
                        retailer: "小売業者",
                        merchant_transaction_id: "マーチャントトランザクションID",
                        transaction_date: "トランザクション日",
                        card_number: "カード番号",
                        descriptor: "決済アカウント名",
                        amount: "合計",
                        merchant_id: "加盟店ID",
                        arn: "アクワイアラレファレンスナンバー",
                        response_message: "応答メッセージ",
                        download_template: "テンプレートをダウンロード",
                        base64_cbdata: "生データ",
                        created_date: "作成日",
                        name: "名前",
                        alerts: "アラート",
                        transactionprocessing: "トランザクション処理",
                        jwt_token: "JWTトークン",
                        generate_jwt: "新しいJWTトークンを生成する",
                        classfile: "クラスファイル",
                        url: "Url",
                        apiurl: "Api Url",
                        autoimport: "クラスファイル",
                        isactive: "状態",
                        password1: "パスワード1",
                        password2: "パスワード2",
                        password3: "パスワード3",
                        chargebackReference: "チャージバックリファレンス",
                        alert_id: "アラート ID",
                        currency: "通貨",
                        token: "トークン",
                        notify_url:"通知URL",
                        chargeback_date:"チャージバック日",
                        alertprovider:"Alert Provider",
                        auth1:"Auth1",
                        auth2:"Auth2",
                        auth3:"Auth3"
                    },
                    messages: {
                        upload_successful: "アップロードに成功",
                        file_must_smaller: "ファイルは700KB未満である必要があります",
                        document_upload_successful: "ドキュメントのアップロードに成功しました",
                        chargeback_import_successful: "チャージバックのインポートが成功しました",
                        chargeback_create_successful: "チャージバックの作成が成功",
                        notice_1: "最初の行に製品ではなくヘッダーが含まれていること、およびフィールド区切り文字がコンマに設定されていることを確認してください",
                    },
                    tooltip: {
                        max_upload_file_size: "アップロードファイルの最大サイズ",
                        alert_matched: "アラートが一致しました",
                        transaction_matched: "トランザクションが一致しました",
                        require_documents_upload: "ドキュメントのアップロードが必要です！",
                    },
                },
                alerts: {
                    title: "アラート",
                    title_expiry: "アラート - 今日期限切れ",
                    status: "ステータス",
                    reason: "理由",
                    alert_id: "アラート ID",
                    refunded: "売上取消",
                    not_settled: "未売上",
                    not_refunded: "再売上",
                    update_status: "ステータスアップデート",
                    alert_details: "アラート内容",
                    duplicate_alertid: "重複アラート ID",
                    update_status_successful: "ステータスのアップデート完了",
                    receive_data: "データの受領",
                    alertdate: "アラート発生日",
                    sitename: "サイト名",
                    alert_type: "アラートタイプ",
                    transaction_date: "トランザクション日",
                    transaction_amount: "トランザクション合計",
                    transaction_currency: "トランザクション通貨",
                    providername: "プロバイダー",
                    retailername: "小売店名",
                    provider: "プロバイダー",
                    provider_caseid: "プロバイダー情報",
                    cardnumber: "カード情報",
                    cardtype: "Card Type",
                    merchantname: "加盟店",
                    descriptor: "決済アカウント名",
                    issuername: "カード発行会社",
                    source: "情報源",
                    arn: "アクワイアラレファレンスナンバー",
                    authcode: "与信コード",
                    outcome: "処理結果",
                    outcome_updateon: "処理更新日",
                    merchant_request: "加盟店リクエスト",
                    merchant_transaction_id: "加盟店トランザクションID",
                    merchant_updated_on: "加盟店アップデート日",
                    belongtomerchant: "加盟店のものか",
                    chargeback_proceeded: "チャージバック確定",
                    is_duplicate: "重複",
                    alert_credits: "アラートクレジット",
                    alert_credit_documents: "アラートクレジットドキュメント",
                    merchant_updates: "マーチャントの更新",
                    repost_alert: "アラートの再投稿",
                    request_credit: "クレジットをリクエストする",
                    credit_reason: "クレジット理由",
                    add_to_batch: "バッチに追加",
                    status_updates: "Status Updates",
                    comment: "コメント",
                    yes: "はい",
                    no: "いいえ",
                    mcc: "MCC",
                    tier: "ティア",
                    count: "Count",
                    stat_title: "Alert Statistics",
                    please_select_sid: "Please Select Site",
                    amount_refunded: "返金額",
                    tooltip: {
                        outcome: "前回加盟店のシステムより抽出された処理結果ステータス",
                        outcome_updateon: "アラートプロバイダーより更新された日付",
                        merchant_updated_on: "加盟店がTxshieldに変更した最後の日付",
                        receive_data: "最新データの表示",
                        potential_transaction_matches: "潜在的なトランザクションの一致",
                    },
                    required_messages: {
                        duplicate_alertid_required: "重複アラートIDを入力してください",
                        amount_refunded_required: "返金される金額を入力してください。",
                        duplicate_alertid_not_valid: "重複するアラートIDが無効です",
                    },
                    dispute_condition_code:"紛争条件コード",
                    update_warning:"更新は発行者に送信されます。ただし、すべての発行者が更新を受け入れるわけではなく、最初に送信されたステータスのみを承認する発行者もいます。"
                },
                rdractivity: {
                    title: "RDRアクティビティ",
                },
                profile: {
                    title: "基本情報",
                    tabs: {
                        general_information: "詳細情報",
                        contact: "連絡先",
                        group: "グループ",
                        change_password: "パスワード変更",
                    },
                    form: {
                        submit: "決定",
                        username: "ユーザー名",
                        first_name: "姓",
                        last_name: "名",
                        email: "電子メール",
                        client_ip_restrictions: "IP制限",
                        company: "会社名",
                        position: "役職",
                        primary_contact: "担当者連絡先",
                        financial_contact: "経理連絡先",
                        technical_contact: "システム担当者連絡先",
                        support_contact: "サポート連絡先",
                        comment: "コメント",
                        phone: "電話番号",
                        mobile: "携帯番号",
                        other: "その他",
                        address: "住所",
                        city: "市区町村",
                        state: "県",
                        postcode: "郵便番号",
                        country: "国",
                        old_password: "前のパスワード",
                        new_password: "新しいパスワード",
                        confirm_password: "パスワードの再入力",
                        "2fa_option": "2FAオプション",
                        secret_key: "シークレットキー",
                        authenticator_app: "オーセンティケーターアプリ",
                        user_theme: "Theme",
                        required_messages: {
                            inform_fname_required: "姓を入力してください！",
                            inform_lname_required: "名を入力してください！",
                            inform_email_required: "メールアドレスの入力をしてください！",
                            changepass_old_required: "前のパスワードを入力してください！",
                            changepass_new_required: "新しいパスワードを入力してください！",
                            changepass_same_current: "新しいパスワードは前のパスワードと違うパスワードにしてください！!",
                            changepass_confirm_required: "パスワードの再入力をしてください！",
                            changepass_confirm_nomatch: "パスワードの再入力が一致しません！",
                            changepass_new_min8: "新しいパスワードは8文字以上である必要があります",
                            changepass_confirm_min8: "パスワードの確認は8文字以上である必要があります",
                        },
                    },
                },
                alertsforreview: {
                    title: "レビューのためのアラート",
                },
                oi: {
                    merchants: "TxFind - 加盟店",
                    bincaids: "TxFind - BIN/CAID",
                    cart_list: "TxFind - カートリスト",
                    cart_details: "TxFind - カートの詳細",
                    lookup_history: "TxFind - ルックアップ履歴",
                    new_merchant: "新しい加盟店",
                    create_merchant: "マーチャントを作成する",
                    view_merchant: "マーチャントを表示",
                    received_data: "受信データ",
                    return_data: "データを返す",
                    tabs: {
                        transaction_details: "取引の詳細",
                        customer_information: "顧客情報",
                        past_transactions: "過去の取引",
                        product_purchased: "購入した製品",
                        industry_details_flight_details: "業界の詳細-フライトの詳細",
                        industry_details_hotel_details: "業界の詳細-ホテルの詳細",
                        delivery_details: "配達の詳細",
                        physical_fulfillment: "物理的フルフィルメント",
                        digital_service_delivery: "デジタルサービスの提供",
                        crm_details: "CRMの詳細",
                        lookup_history: "ルックアップ履歴",
                        attachments: "添付ファイル"
                    },
                    form: {
                        name: "名前",
                        arn: "ARN",
                        merchant_id: "販売者ID",
                        jwt_token: "JWTトークン",
                        generate_jwt: "新しいJWTトークンを生成する",
                        txshieldoicartreference: "TxFindカートリファレンス",
                        lookupreference: "ルックアップリファレンス",
                        lookupdate: "ルックアップ日",
                        receiveddata: "受信データ",
                        returndata: "データを返す",
                        merchantid: "販売者ID",
                        merchantref: "マーチャントリファレンス",
                        paymentdescriptor: "決済アカウント名",
                        authorizationcode: "認証コード",
                        authorizationdate: "承認日",
                        authorizationamount: "承認額",
                        settlementdate: "決済日",
                        settlementamount: "決済金額",
                        transactiondate: "取引日",
                        cardno: "クレジットカード番号",
                        alerthappened: "アラート",
                        alertreference: "アラートリファレンス",
                        chargebackhappened: "チャージバック",
                        chargebackreference: "チャージバックリファレンス",
                        datecreated: "日にち",
                        attachmentsid: "添付ファイルID",
                        attachmentsfile: "添付ファイルファイル",
                        taxamount: "タックス",
                        billingaddressdetails: "請求先住所",
                        shippingandhandlingamount: "送料および手数料",
                        shippingaddressdetails: "お届け先の住所",
                        totalamount: "合計",
                        dateofrefund: "返金日",
                        refundamount: "返金",
                        refundprocessed: "払い戻しが処理されました",
                        avsresultcode: "AVS結果",
                        avschecked: "AVSチェック済み",
                        cvvresultcode: "CVVの結果",
                        cvvchecked: "CVVチェック済み",
                        threedsecicode: "3DSEciコード",
                        threedsauthenticationvalue: "3DS認証値",
                        devicename: "装置名",
                        deviceid: "デバイスID",
                        devicelocation: "デバイスの場所",
                        ipaddress: "IPアドレス",
                        refundpolicy: "返金について",
                        termsandconditions: "規約と条件",
                        customername: "顧客名",
                        firsttransactiondate: "最初の取引日",
                        emailaddress: "電子メールアドレス",
                        lengthofrelationship: "関係の長さ",
                        phonenumber: "電話番号",
                    },
                    advanced_search: {
                        value_field: "検索する価値",
                        txshieldoicartreference_field: "TxFindカートリファレンス",
                        arn_field: "ARN",
                        cardnumber_field: "カード番号",
                        descriptor_field: "Descriptor",
                        merchant: "商人",
                        had_lookup: "ルックアップ",
                        alert: "アラート",
                        chargeback: "チャージバック",
                        search_btn: "検索",
                        reset_btn: "リセット",
                    },
                },
                virtual_terminal: {
                    title: "仮想端末",
                    processor_details: "Processor Details",
                    checkout_details: "Checkout Details",
                    additional_details: "Additional Details",
                    form: {
                        process: "Process",
                        item_no: "Item No.",
                        quantity: "Quantity",
                        currency: "Currency",
                        sales_tax: "Sales Tax",
                        tx_action: "TX Action",
                        item_name: "Item Name",
                        description: "Description",
                        amount_unit: "Amount/Unit",
                        affiliate_id: "Affiliate ID",
                        coupon_value: "Coupon Value",
                        shipping_cost: "Shipping Cost",
                        add_cart_item: "Add Cart Item",
                        additional_details: "Additional Details",
                        add_processing_fees: "Add Processing Fees",
                        please_select_site: "Please select site",
                        please_select_currency: "Please select currency",
                        ref_1: "Reference ID 1",
                        ref_2: "Reference ID 2",
                        ref_3: "Reference ID 3",
                        ref_4: "Reference ID 4",
                        sid: "SID",
                        wid: "WID",
                        tid: "TID",
                    },
                },
                possibletransactionmatches: {
                    title: "可能なトランザクションの一致",
                    title_matched: "紛争情報取引マッチ",
                    transaction_info: "取引情報",
                    confirm: {
                        q_1: "一致してもよろしいですか？",
                        notice_1: "次の変更が発生します。",
                        notice_11: "の金額の払い戻しを試みます。",
                        notice_12: "オーダーIDについて",
                        notice_21: "成功した場合、アラートを更新します",
                        notice_22: "以下で：",
                        outcome: "結果: 解決済み",
                        refunded: "返金ステータス: 返金済み",
                        reason: "理由: ケースが解決され、クレジットとキャンセルが処理されました",
                    },
                },
                pagenotfound: {
                    title: "ページが見つかりません",
                    text: "申し訳ありませんが、アクセスしたページは存在しません",
                    button: "ホームページに戻る",
                },
                paymentlink: {
                    title: '支払いリンク',
                    title_not_found: 'あなたのアカウントに関連付けられたサイトはありません。',
                    new_paymentlink: '支払いリンク',
                    form: {
                        sid: "SID",
                        amount: "額",
                        currency: '通貨',
                        payment_description: 'お支払いの説明',
                        expiry_date: '有効期限',
                        first_name: 'ファーストネーム',
                        last_name: '苗字',
                        email: '電子メール',
                        phone: '電話',
                        address:'住所',
                        city: '市',
                        state: '州',
                        country: '国',
                        please_select_site: "サイトを選択してください",
                        please_select_currency: "通貨を選択してください",
                        please_select_country: "国を選択してください",
                        cancel: 'キャンセル',
                        submit: '提出する',
                    }
                },
            },
            tables: {
                fields: {
                    id: "ID",
                    _id: "ID",
                    arn: "ARN",
                    txid: "トランザクションID",
                    name: "サイト名",
                    time: "時間",
                    fees: "手数料",
                    files: "ファイル",
                    amount: "合計",
                    reason: "理由",
                    action: "アクション",
                    status: "ステータス",
                    subject: "タイトル",
                    outcome: "処理結果",
                    reserve: "レザーブ",
                    message: "メッセージ",
                    card_no: "カード番号",
                    currency: "通貨",
                    merchant: "加盟店",
                    reseller: "販売店",
                    detail_id: "情報 ID",
                    sitename: "サイト名",
                    site_name: "サイト名",
                    rcode: "Rcode",
                    site_status: "サイトステータス",
                    retailer_name: "小売店名",
                    timestamp: "タイム",
                    cardnumber: "カード番号",
                    alert_type: "アラートタイプ",
                    alertdate: "アラート日",
                    descriptor: "決済アカウント名",
                    alert_descriptor: "決済アカウント名",
                    reason_code: "コード",
                    cardno: "カード番号",
                    card_number: "カード番号",
                    created_date: "作成日",
                    reseller_name: "販売店名",
                    site_receive: "決済額",
                    merchantname: "加盟店名",
                    merchant_name: "加盟店名",
                    pay_method: "カードブランド",
                    payment_methods: "カードブランド",
                    transactiondate: "トランザクション日",
                    transaction_date: "トランザクション日",
                    response_message: "応答メッセージ",
                    merchant_request: "加盟店リクエスト",
                    merchant_response: "加盟店返信内容",
                    merchant_updatedate: "加盟店更新日",
                    merchant_transaction_id: "マーチャントトランザクションID",
                    matchid: "ID",
                    gwname: "ゲートウェイ",
                    alertid: "アラート ID",
                    orderid: "注文ID",
                    datecreated: "日付",
                    refundstatus: "返金状況",
                    gw_transaction_date:"GW 取引日",
                    gw_transactionid: "GWトランザクションID",
                    retrieval_date:"取得日",
                    provider_caseid: "プロバイダーリファレンス",
                    updated_by: "によって更新されました",
                    txshieldoicartreference: "TxFindカートリファレンス",
                    lookupreference: "ルックアップリファレンス",
                    lookupdate: "ルックアップ日",
                    receiveddata: "受信データ",
                    returndata: "データを返す",
                    merchantid: "販売者ID",
                    merchantref: "マーチャントリファレンス",
                    paymentdescriptor: "決済アカウント名",
                    authorizationcode: "認証コード",
                    authorizationdate: "承認日",
                    authorizationamount: "承認額",
                    settlementdate: "決済日",
                    settlementamount: "決済金額",
                    dateofpurchase: "購入日",
                    amountofpurchase: "購入金額",
                    creditcardnumber: "クレジットカード番号",
                    recurringtransaction: "定期的なトランザクション",
                    productname: "商品名",
                    productdescription: "製品説明",
                    producturl: "製品のURL",
                    unitpriceamount: "単価",
                    quantity: "量",
                    memberrewardsnumber: "メンバーリワード番号",
                    manifest: "マニフェスト",
                    reservationnumber: "予約番号",
                    reservationdate: "予約日",
                    checkindate: "チェックインする日",
                    checkoutdate: "チェックアウト日",
                    shippingcarrierurl: "配送業者のURL",
                    trackingnumber: "追跡番号",
                    dateofshipment: "発送日",
                    dateofdelivery: "配達日",
                    signatureconfirmation: "署名の確認",
                    proofofdelivery: "配達証明",
                    proofofusage: "使用証明",
                    locationofusage: "使用場所",
                    frequencyofusage: "使用頻度",
                    dateofinteraction: "相互作用の日付",
                    contactname: "連絡先",
                    notes: "ノート",
                    provider: "プロバイダー",
                    providername: "プロバイダー名",
                    requested_date: "依頼日",
                    received_date: "受け取った日付",
                    comment: "コメント",
                    status_reason: "ステータスの理由",
                    to: "に",
                    type: "タイプ",
                    date: "日にち",
                    keyword: "キーワード",
                    field: "分野",
                    total: "合計",
                    item_no: "商品番号",
                    tx_items: "TXアイテム",
                    per_unit: "ユニットあたり",
                    description: "説明",
                    trackstatus: "ステータスの追跡",
                    trackingstatus: "ステータスの追跡",
                    comm_to: "コミュニケーション先",
                    comm_status: "通信状態",
                    tx_action: "TXアクション",
                    default_gateway: "デフォルトゲートウェイ",
                    testmethod: "試験方法",
                    mcc: "MCC",
                    bin: "Bin",
                    caid: "Caid",
                    bank_mid: "Bank MID",
                    verifi: "Verifi CDRN",
                    ethoca: "Ethoca",
                    rdr: "RDR",
                    requestedDate: "依頼日",
                    updatedDate: "更新日",
                    referenceNumber: "参照番号.",
                    expiry_date: "有効期限",
                    request: "リクエスト",
                    response: "応答",
                    datetime: "日付時刻",
                    matchscore: "マッチスコア",
                    alertstatus:"アラート ステータス",
                    card_type: "カードの種類",
                    mid: "MID",
                    reasoncode:"理由コード",
                    alerts:"アラート",
                    transactionprocessing:"トランザクション処理",
                    classfile:"クラスファイル",
                    apiurl:"API Url",
                    active:"アクティブ",
                    autoimport:"自動インポート",
                    version:"Version",
                    token:"Token",
                    issued_at_date:"Issued Date",
                    alertprovider: "Alert Provider",
                    balance:"バランス",
                    auth_code: "認証コード",
                    caseid: "ケース ID",
                    alert_id: "アラート ID",
                    linked_provider_caseid: "プロバイダー参照",
                    case_event: "ケース イベント",
                    file_download_date: "ファイルのダウンロード日",
                    case_received_date: "ケース受付日",
                    payment_description: 'お支払いの説明',
                    link_text: 'リンク',
                    first_name: 'ファーストネーム',
                    last_name: '苗字',
                    phone: '電話',
                    address: '住所',
                    city: '市',
                    payment_status: '支払い状況',
                    show_delete_only: '削除のみを表示',
                    delete_case_id: 'ケースIDの削除',
                    delete_received_date: '受信日の削除',
                    contact: '接触',
                    remarks: '備考',
                    verbose: '詳細モード'
                },
                actions: {
                    ok: "OK",
                    view: "表示",
                    date: "日付",
                    items: "アイテム",
                    reset: "リセット",
                    match: "一致",
                    update: "アップデート",
                    search: "サーチボタン",
                    export: "輸出",
                    resolve: "解決する",
                    download: "ダウンロード",
                    selected: "選択",
                    clear_all: "全てクリア",
                    date_range: "日付範囲",
                    export_all: "全て抽出",
                    more_details: "詳細",
                    clear: "クリア",
                    cancel: "キャンセル",
                    clear_filters: "フィルター取消",
                    export_fields: "エクスポートフィールド",
                    export_headers: "ヘッダーのエクスポート",
                    export_settings: "エクスポート設定",
                    export_selected: "選択内の抽出",
                    view_alert_data: "アラートデータの表示",
                    action_required: "アクション選択",
                    document_list: "ドキュメントリスト",
                    document_upload: "ドキュメントのアップロード",
                    clear_filters_sorters: "全ての条件取消",
                    hide_column: "列を非表示",
                    advanced_search: "高度な検索",
                    export_error: "エクスポートエラー",
                },
                filters: "フィルター",
                sorters: "抽出",
                loading: "読み込み中",
                emptytext: "フィルタが適用され、一部のデータが非表示になります",
                emptytext_email_to_3ds: "3DSの使用を開始するための電子メールサポート",
                display: "画面",
                show_total: "{{from}}-{{to}} / {{total}} アイテム",
            },
            outcomes: {
                ok: "Ok",
                wip: "確認中",
                other: "その他",
                error: "エラー",
                cancel: "キャンセル",
                pending: "保留中",
                matched: "一致",
                declined: "失敗決済",
                resolved: "解決済み",
                processing: "処理中",
                "e-commerce": "Eコマース",
                "manual entry": "手動入力",
                manual_receive: "手動受信",
                merchant_receive: "アラート数",
                cb_proceeded: "チャージバック続行",
                shipper_contacted:"荷送人に連絡済み",
                missed:"逃した",
                previously_credited:"以前にクレジットされた",
                notfound:"見つかりません",
                account_suspended:"アカウントが一時停止",
                partially_stopped:"一部停止中",
                stopped:"停止", 
                cancelled:"キャンセル", 
                previously_refunded:"以前に返金されました",
                unresolved_dispute:"未解決の紛争",
                duplicate:"重複",
                previously_received_chargeback:"以前に受け取ったチャージバック",
                account_closed:"アカウントが閉鎖されました",
                descriptor_does_not_belong_to:"記述子が属していない",
                refund_failed:"返金に失敗しました", 
            },
            specialset: {
                other: "その他",
                matched: "一致",
                pending: "保留中",
                declined: "失敗決済",
                resolved: "解決済み",
                duplicate: "重複",
                processing: "処理中",
                update: "アップデート",
                "action required": "アクション選択",
                refunded: "返金済み",
                not_refunded: "返金されません",
                refund_exists: "払い戻しが存在します",
                cannot_be_refunded: "返金不可",
                partial_refund_exists: "部分的な払い戻しが存在します",
                chargeback_exists: "チャージバックあり",
                marked_alert:"マークされたアラート",
                not_marked:"マークなし",
                void_exists:"ボイドが存在する"
            },
            refundstatus: {
                refunded: "返金済み",
                not_refunded: "返金されません",
                refund_exists: "払い戻しが存在します",
                void_exists:"ボイドが存在する"
            },
            reason_descriptions: {
                case_resolved_credit__cancellation_processed: "ケースが解決され、クレジットとキャンセルが処理されました",
                case_resolved_partial_credit__cancellation_processed: "ケースが解決され、部分的なクレジットとキャンセルが処理されました",
                case_resolved_authorization_cancelled: "ケースが解決され、承認がキャンセルされました",
                account_cancelledsuspended: "アカウントのキャンセル/一時停止",
                account_cancelled__suspended: "アカウントのキャンセル/一時停止",
                cancellation_processed: "キャンセル処理済み",
                transaction_previously_credited_for_case_amount_no_balance_remaining: "以前にケース金額にクレジットされたトランザクション、残高が残っていない",
                contacted_the_shipper_and_attempting_intercept_of_goods: "荷送人に連絡し、商品の傍受を試みました",
                duplicate_request: "重複リクエスト",
                merchant_account_closed_unable_to_process_credit: "マーチャントアカウントが閉鎖され、クレジットを処理できません",
                transaction_previously_received_a_chargeback_no_balance_remaining:
                    "トランザクションは以前にチャージバックを受け取りましたが、残高は残っていません",
                could_not_honour_cancel_request: "キャンセルリクエストを受け付けませんでした",
                matched_unable_to_stop_order_fulfilment: "一致し、オーダーフルフィルメントを停止できません",
                matched_proceed_with_chargeback_for_resolution: "一致し、解決のためにチャージバックを続行します",
                unmatched_case__generalother: "比類のないケース-一般/その他",
                unmatched_case__unable_to_locate_original_transaction: "一致しないケース-元のトランザクションを見つけることができません",
                request_is_outside_of_eligibility_timeframe: "リクエストが対象期間外です",
                transaction_3d_secure_authenticated_successfully_verified_by_visamastercard_secure_code:
                    "トランザクション3Dセキュアが正常に認証されました（Visa / MasterCardセキュアコードによる検証）",
                work_in_progress: "進行中の作業",
                error_details: "エラーの詳細",
                unmatched_case__descriptor_does_not_belong_to_merchant:"一致しないケース - 記述子は販売者に属していません"
            },
            alert_type: {
                "confirmed fraud": "確認された詐欺",
                "customer dispute": "顧客紛争",
                dispute: "紛争",
                dispute_notice: "紛争通知",
                dispute_rdr: "紛争_rdr",
                fraud_notice: "詐欺の通知",
            },
            required_field: "これは必要項目です",
            required_sid_field: "サイトは必須項目です",
            please_select: "選んでください",
            today: "今日",
            min_1_day:"Invalid date range",
            max_3_months: "日付範囲は最大3か月である必要があります",
            last_7_days: "過去7日間",
            last_30_days: "過去30日間",
            this_month: "今月",
        },
    },
};
