import React, { useState, useEffect } from "react";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
import { PageHeader, Card, Form, Select, Row, Col, Button, Checkbox, Input } from "antd";
import { API_TXALERT, API_SITE } from "../../config/config";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import BreakdownByCondition from "./StatsDetails/BreakdownByCondition";
// import BreakdownByReason from "./StatsDetails/BreakdownByReason";
import BreakdownByType from "./StatsDetails/BreakdownByType";
import BreakdownByProvider from "./StatsDetails/BreakdownByProvider";
import MerchantList from "./../../components/MerchantList";
import RetailerList from "./../../components/RetailerList";
import SiteList from "./../../components/SiteList";
import Swal from "sweetalert2";

const { Option } = Select;
const SIDS_URL = API_SITE + "/list";
const STATS_URL = API_TXALERT + "/stats";
const ALERTTYPES_URL = API_TXALERT + "/type";
const PROVIDER_DATA_URL = API_TXALERT + "/provider";
const REASONCODE_DATA_URL = API_TXALERT + "/provideralertreasons";

const MAX_DATERANGE = 12;
const DEFAULT_DATERANGE = 7;
const DEFAULT_RANGETYPE = "days";
const START_DATE = moment().subtract(DEFAULT_DATERANGE, DEFAULT_RANGETYPE);
const END_DATE = moment();
const DATE_FORMAT = "YYYY-MM-DD";

const AlertStats = (props) => {
    const [{ data }] = DataFetchHookV3(SIDS_URL);
    const [alertTypeList,setAlertTypeList] = useState(false);
    const [providerList,setProviderList] = useState(false);
    const [selectedSID, setSelectedSID] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [statsData, setStatsData] = useState(null);
    const [detailsBoxTitle, setDetailsBoxTitle] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [formData, setFormData] = useState();
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const { t } = props;
    const [isDateExceeded, setIsDateExceeded] = useState(false); // eslint-disable-line
    const [groupByColumns, setGroupByColumns] = useState(['alert_type','issuer','providername','card_type']);
    const [alertTypeSearchVisible, setAlertTypeSearchVisible] = useState(true);
    const [conditionCodeSearchVisible, setConditionCodeSearchVisible] = useState(true);
    const [providerSearchVisible, setProviderSearchVisible] = useState(true);
    const [reasonList, setReasonList] = useState(false);
    const [midSelect, setMidSelect] = useState('');
    const [ridSelect, setRidSelect] = useState('');
    const [requestData, setRequestData] = useState('');

    const onTypeSelectorChange = async (value) => {
        await setSelectedType(value);
        setDetailsBoxTitle(value);
        setAlertTypeSearchVisible(value !== "alert_type");
        setConditionCodeSearchVisible(value !== "conditioncode");
        setProviderSearchVisible(value !== "provider");
    };

    useEffect(() => {
        if (!alertTypeList) {
            getAlertTypes();
        }
        if (!providerList) {
            getProviders();
        }
        if (!reasonList) {
            getReasons();
        }
    },[]);  // eslint-disable-line

    const onSIDSelectorChange = async (value) => {
        await setSelectedSID(value);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
        setStatsData(null);
    };

    const checkboxOnChange = (checkedValues) => {
        setGroupByColumns(checkedValues);
    };
    
    const groupColumnOptions = [
        {
          label: 'Alert Type',
          value: 'alert_type',
        },
        {
          label: 'Issuer Name',
          value: 'issuer',
        },
        {
          label: 'Provider',
          value: 'providername',
        },
        {
          label: 'Card Type',
          value: 'card_type',
        }
    ];

    const getAlertTypes = () => {
        axios
            .post(
                ALERTTYPES_URL,
                {},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setAlertTypeList(data);
            })
            .catch(() => false);
    }

    const getProviders = () => {
        axios
            .post(
                PROVIDER_DATA_URL,
                {},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setProviderList(data);
            })
            .catch(() => false);
    }

    const getReasons = () => {
        axios
            .post(
                REASONCODE_DATA_URL,
                {},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setReasonList(data);
            })
            .catch(() => false);
    }

    const onFinish = () => {
        if (!(alertTypeSearchVisible && conditionCodeSearchVisible) && (selectedSID === null || selectedSID.length === 0)){
            Swal.fire({
                icon: "error",
                title: t("required_sid_field"),
                showConfirmButton: true
            });
            return;
        }

        setFetching(true);

        let startDate = null;
        let endDate = null;
        let sid = null;
        let postData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }

            if (formData.dateValue) {
                startDate = formData.dateValue ? formData.dateValue : null;
                endDate = formData.dateValue ? formData.dateValue : null;
            }

            if(!startDate && endDate) {
                startDate = moment().subtract(31, "days");
            } else if(startDate && !endDate) {
                endDate = moment().add(31, "days");
            } else if(!startDate && !endDate) {
                startDate = moment().subtract(31, "days");
                endDate = moment();
            }

            if(!(alertTypeSearchVisible && conditionCodeSearchVisible)){
                sid = selectedSID !== null ? selectedSID.toString() : '';
            }

            if(!providerSearchVisible){
                sid = formData.site;
            }

            
            postData = {
                dateFrom: startDate.format(DATE_FORMAT),
                dateTo: endDate.format(DATE_FORMAT),
                sid: sid,
                type: formData.type,
                alertType: formData.alerttype,
                issuer: formData.issuername,
                providerId: formData.provider,
                cardType: formData.cardtype,
                mid: formData.merchant,
                rid: formData.retailer,
                reasoncode: formData.reasoncode
            };

            if (groupByColumns!=null) {
                postData['groupby'] = groupByColumns;
            }

            if(formData.type === 'alert_type'){
                postData['groupby'] = ['alert_type'];
                postData['sortColumn'] = 'type';
                postData['sortOrder'] = 'ascend';
            }

            setRequestData(postData);
            axios
                .post(STATS_URL, postData, {
                    headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") },
                })
                .then(async ({ data }) => {
                    setStatsData(data);
                    setFetching(false);
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        showConfirmButton: true
                    });
                });
        }
    };

    const initFormData = {
        dateRange: [START_DATE, END_DATE],
        type: null,
        sid: null,
    };

    const [form] = Form.useForm();

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeRetailer = async (value) => {
        await setRidSelect(value)
    }

    const onChangeMerchant = async (value) => {
        await setMidSelect(value)
    }

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="type" rules={[{ required: true, message: t("required_field") }]}>
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Please select report type"
                                    value={selectedType}
                                    onChange={onTypeSelectorChange}
                                >
                                    <Option value="alert_type">Alert Type</Option>
                                    {/*<Option value="reason">Condition Code</Option>*/}
                                    <Option value="conditioncode">Dispute Condition Code</Option>
                                    <Option value="provider">Provider</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col 
                            hidden={alertTypeSearchVisible && conditionCodeSearchVisible}
                            className="gutter-row" 
                            xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            {/*<Form.Item name="sid" rules={[{ required: true, message: t("required_field") }]}>*/}
                                <Select
                                    mode="multiple"
                                    showArrow="true"
                                    maxTagCount="responsive"
                                    allowClear
                                    // value={selectedSID}
                                    onChange={onSIDSelectorChange}
                                    disabled={fetching}
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={t("contents.alerts.please_select_sid")}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                >
                                    {data.datalist.map((item) => (
                                        <Option key={item.sid} value={item.sid}>
                                            {item.name + ` (sid: ` + item.sid + `)`}
                                        </Option>
                                    ))}
                                </Select>
                            {/*</Form.Item>*/}

                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange" rules={[{ required: true, message: t("required_field") }]}>
                                <CustomDateRangeV3 t={t} setIsDateExceeded={setIsDateExceeded} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} hidden={providerSearchVisible}>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="merchant">
                                <MerchantList placeholder={t("contents.merchants.title")} onChange={onChangeMerchant}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="retailer">
                                <RetailerList placeholder={t("contents.retailers.title")} mid={midSelect} onChange={onChangeRetailer}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="site">
                                <SiteList placeholder={t("contents.sites.title")} mid={midSelect} rid={ridSelect}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="reasoncode">
                                <Select
                                    mode="multiple"
                                    showArrow="true"
                                    maxTagCount="responsive"
                                    allowClear
                                    disabled={fetching}
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={t("contents.alerts.reasoncode")}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    <Option value="all">All</Option>
                                    {reasonList ? reasonList.map((item) => (
                                        <Option key={item.concatenated_reason} value={item.concatenated_reason}>
                                            {item.concatenated_reason}
                                        </Option>
                                    )) : null}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} hidden={conditionCodeSearchVisible}>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="alerttype">
                                <Select
                                    disabled={fetching}
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder={t("contents.alerts.alert_type")}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                >
                                    <Option value="">All</Option>
                                    {alertTypeList ? alertTypeList.map((item) => (
                                        <Option key={item.alert_type} value={item.alert_type}>
                                            {item.alert_type}
                                        </Option>
                                    )) : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="issuername">
                                <Input placeholder={t("contents.alerts.issuername")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="provider">
                                <Select
                                    disabled={fetching}
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder={t("contents.alerts.providername")}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                >
                                    <Option value="">All</Option>
                                    {providerList ? providerList.map((item) => (
                                        <Option key={item.key} value={item.alertproviderid}>
                                            {item.title}
                                        </Option>
                                    )) : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="cardtype">
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder={t("contents.alerts.cardtype")}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                >
                                    <Option value="">All</Option>
                                    <Option value="visa">VISA</Option>
                                    <Option value="mastercard">Mastercard</Option>
                                    <Option value="amex">AMEX</Option>
                                    <Option value="discover">Discover</Option>
                                    <Option value="diners">Diners</Option>
                                    <Option value="jcb">JCB</Option>
                                    <Option value="maestro">Maestro</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} hidden={conditionCodeSearchVisible}>
                        <Col className="gutter-row" xs={{ span: 24 }} /* md={{ span: 8 }} lg={{ span: 12, offset: 0 }} xl={{ span: 12, offset: 2 }} */ >
                            Show: <Checkbox.Group options={groupColumnOptions} defaultValue={groupByColumns} onChange={checkboxOnChange} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8, offset: 16 }} lg={{ span: 6, offset: 0 }} xl={{ span: 4, offset: 20 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block loading={fetching} disabled={fetching}>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    return (
        <div>
            <PageHeader title={t("contents.alerts.stat_title")}></PageHeader>
            {AdvancedSearchForm()}
            {statsData && !fetching ? (
                <>
                    {formData.type === "conditioncode" && statsData && !fetching ? (
                        <BreakdownByCondition t={t} statsData={statsData} requestData={requestData} detailsBoxTitle={detailsBoxTitle} tableColumns={groupByColumns} setTableColumns={setGroupByColumns} />
                    ) : null}
                    {/*{formData.type === "reason" && statsData && !fetching ? (*/}
                    {/*    <BreakdownByReason t={t} statsData={statsData} detailsBoxTitle={detailsBoxTitle} />*/}
                    {/*) : null}*/}
                    {formData.type === "alert_type" && statsData && !fetching ? (
                        <BreakdownByType t={t} statsData={statsData} requestData={requestData} detailsBoxTitle={detailsBoxTitle} />
                    ) : null}
                    {formData.type === "provider" && statsData && !fetching ? (
                        <BreakdownByProvider t={t} statsData={statsData} requestData={requestData} detailsBoxTitle={detailsBoxTitle} />
                    ) : null}
                </>
            ) : null}
        </div>
    );
};
export default AlertStats;
