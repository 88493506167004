import React ,{ useState} from "react";
import {Tag,Table} from "antd";
import ExportModalV3 from "../../../helper/ExportModalV3";
import { API_TXALERT } from "../../../config/config";
import moment from "moment";
const DATA_URL_EXPORT = API_TXALERT + "/typestatistics/reports";

const BreakdownByType = (props) => {
    const { t, statsData, requestData, detailsBoxTitle } = props; // eslint-disable-line

    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "Alert Type", key: "type" },
        { label: "Count", key: "count" }       
    ];

    // Convert statsData to an array for the table
    const data = statsData
    ? Object.keys(statsData.alert_type).map((key) => ({
        key,
        type: statsData.alert_type[key]?.alert_type,
        count: statsData.alert_type[key]?.count,
      }))
    : [];

    // Default requestData
  const [modifiedRequestData, setModifiedRequestData] = useState({
    ...requestData, 
    groupby: ["alert_type"],
    sortColumn: null,
    sortOrder: null,
  });

  
  // Handle sorting changes
  const handleTableChange = (pagination, filters, sorter) => {
    setModifiedRequestData((prev) => ({
      ...prev,
      sortColumn: sorter.columnKey || null,
      sortOrder: sorter.order || null,
    }));
  };

    
    const getDetails = () => (
        <div className="table-v3">
            <div className="ant-table ant-table-small">
                <div className="ant-table-title">
                    <div className="custom-table--header d-flex justify-content-between align-items-center">
                        <h3 className="table-title">{t(`contents.alerts.${detailsBoxTitle}`)}</h3>
                        <ExportModalV3
                            t={t}
                            keyname="export_all"
                            title={t("tables.actions.export")}
                            data={modifiedRequestData}
                            data_url={DATA_URL_EXPORT}
                            headers={exportHeaders}
                            fieldHeadersOptional={exportHeadersOptional}
                            filename={`typestatistics_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                            style={{ marginLeft: 8 }}
                        />
                    </div>
                </div>
                <div className="ant-table-container">
                    <div className="ant-table-content">
                        <Table dataSource={data} pagination={false} onChange={handleTableChange}>
                        <Table.Column
                            title={t("contents.alerts.alert_type")}
                            dataIndex="type"
                            key = "type"
                            sorter={(a, b) => a.type.localeCompare(b.type)}
                            width="70%"
                        />
                        <Table.Column
                            title={<div className="text-center">{t("contents.alerts.count")}</div>}
                            dataIndex="count"
                            key="count"
                            sorter={(a, b) => a.count - b.count}
                            align="center"
                            width="30%"
                        />
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );

    return getDetails();
};

export default BreakdownByType;
