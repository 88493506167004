import React, { useState, useEffect } from "react";
import { PageHeader, Table, Tabs, Card, Form, Row, Col, Empty, Spin, message } from "antd";
import FormatNumber from "../../helper/FormatNumber";
import { OI_URL } from "../../config/config";
import axios from "axios";
import moment from "moment";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { TabPane } = Tabs;

const CartDetail = (props) => {
    const [data, setData] = useState(null);
    const [lookup, setLookup] = useState([]);
    const [attachment, setAttachment] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = props;

    const formItemLayout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
    };

    useEffect(() => {
        const loadCartData = async () => {
            if (!props.match?.params?.id) {
                setLoading(false);
                return;
            }

            try {
                const token = await TxFindAccessToken();
                const cartId = props.match.params.id;

                const cartResponse = await axios.post(
                    `${OI_URL}/api/v1/txshield/cartDetails/${cartId}`,
                    {},
                    { 
                        headers: { 
                            Authorization: `Bearer ${token}` 
                        }
                    }
                );

                if (cartResponse.data) {
                    setData(cartResponse.data);

                    try {
                        const lookupResponse = await axios.post(
                            `${OI_URL}/api/v1/txshield/lookupHistory/`,
                            {
                                field: ["txShieldOICartReference"],
                                value: [cartId],
                                operator: ["="],
                                page: 1,
                                size: 10,
                                order: "DESC"
                            },
                            { 
                                headers: { 
                                    Authorization: `Bearer ${token}` 
                                }
                            }
                        );

                        setLookup(lookupResponse.data.list || []);
                    } catch (lookupError) {
                        console.error('Error loading lookup history:', lookupError);
                        message.warning('Could not load lookup history');
                    }

                    try {
                        const attachmentResponse = await axios.get(
                            `${OI_URL}/api/v1/cart/${cartId}/attachment/`
                        );
                        setAttachment(attachmentResponse.data);
                    } catch (attachmentError) {
                        console.error('Error loading attachments:', attachmentError);
                        message.warning('Could not load attachments');
                    }
                }
            } catch (error) {
                console.error('Error loading cart data:', error);
                message.error(error.response?.data?.msg || 'Error loading cart data');
            } finally {
                setLoading(false);
            }
        };

        loadCartData();
    }, [props.match?.params?.id]);

    const getPlainText = (text) => text || '-';

    const getLink = (link) => {
        if (!link) return '-';
        return (
            <a href={link} target="_blank" rel="noopener noreferrer" className="ant-form-text">
                {link}
            </a>
        );
    };

    const getFormatDate = (date) => {
        if (!date || !moment(date).isValid()) return '-';
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
    };

    const getCartDetailsLeftForm = () => {
        if (!data) return null;

        const fields = [
            {
                label: t("contents.oi.form.txshieldoicartreference"),
                name: "txShieldOICartReference",
                value: getPlainText(data.txShieldOICartReference),
            },
            {
                label: t("contents.oi.form.arn"),
                name: "arn",
                value: getPlainText(data.arn),
            },
            {
                label: t("contents.oi.form.transactiondate"),
                name: "transactionDate",
                value: getFormatDate(data.transactionDate),
            },
            {
                label: t("contents.oi.form.authorizationcode"),
                name: "authorizationCode",
                value: getPlainText(data.authorizationCode),
            },
            {
                label: t("contents.oi.form.authorizationdate"),
                name: "authorizationDate",
                value: getFormatDate(data.authorizationDate),
            },
            {
                label: t("contents.oi.form.settlementdate"),
                name: "settlementDate",
                value: getFormatDate(data.settlementDate),
            },
            {
                label: t("contents.oi.form.alerthappened"),
                name: "alertHappened",
                value: getPlainText(data.alertHappened ? t("contents.alerts.yes") : t("contents.alerts.no")),
            },
            {
                label: t("contents.oi.form.chargebackhappened"),
                name: "chargebackHappened",
                value: getPlainText(data.chargebackHappened ? t("contents.alerts.yes") : t("contents.alerts.no")),
            },
            {
                label: t("contents.oi.form.datecreated"),
                name: "dateCreated",
                value: getFormatDate(data.dateCreated),
            },
        ];

        return showCardTwoBlockInfo(fields);
    };

    const getCartDetailsRightForm = () => {
        if (!data) return null;

        const fields = [
            {
                label: t("contents.oi.form.merchantid"),
                name: "merchantId",
                value: getPlainText(data.merchantId),
            },
            {
                label: t("contents.oi.form.paymentdescriptor"),
                name: "paymentDescriptor",
                value: getPlainText(data.paymentDescriptor),
            },
            {
                label: t("contents.oi.form.authorizationamount"),
                name: "authorizationAmount",
                value: data.authorizationAmount ? 
                    getPlainText(`${FormatNumber(data.authorizationAmount)} ${data.authorizationCurrency}`) : 
                    '-',
            },
            {
                label: t("contents.oi.form.settlementamount"),
                name: "settlementAmount",
                value: data.settlementAmount > 0 ? 
                    getPlainText(`${FormatNumber(data.settlementAmount)} ${data.settlementCurrency}`) : 
                    '-',
            },
            {
                label: t("contents.oi.form.cardno"),
                name: "cardno",
                value: data.creditCardBin && data.creditCardLastFour ? 
                    getPlainText(`${data.creditCardBin}******${data.creditCardLastFour}`) : 
                    '-',
            },
            {
                label: t("contents.oi.form.alertreference"),
                name: "alertReference",
                value: getPlainText(data.alertReference),
            },
            {
                label: t("contents.oi.form.chargebackreference"),
                name: "chargebackReference",
                value: getPlainText(data.chargebackReference),
            },
        ];

        return showCardTwoBlockInfo(fields);
    };


    const getTransactionDetailsForm = () => {
        let tx = data.details.transactionDetail ? data.details.transactionDetail : null;
        if (!tx) {
            return showCardEmpty();
        }
        let billingAddress =
            tx && tx.billingAddressDetails ? (Array.isArray(tx.billingAddressDetails) ? tx.billingAddressDetails[0] : tx.billingAddressDetails) : null;
        let shippingAddress = tx && tx.shippingAddressDetails && tx.shippingAddressDetails[0] ? tx.shippingAddressDetails[0] : null;
        let fields = [
            {
                label: t("contents.oi.form.taxamount"),
                name: "taxAmount",
                value: getPlainText(`${FormatNumber(tx.taxAmount)} ${tx.taxAmountCurrency}`),
            },
            {
                label: t("contents.oi.form.billingaddressdetails"),
                name: "billingAddressDetails",
                value: getPlainText(
                    billingAddress
                        ? `${billingAddress.address[0]} ${billingAddress.city} ${billingAddress.state} ${billingAddress.zip} ${billingAddress.country}`
                        : null
                ),
            },
            {
                label: t("contents.oi.form.shippingandhandlingamount"),
                name: "shippingAndHandlingAmount",
                value: getPlainText(`${FormatNumber(tx.shippingAndHandlingAmount)} ${tx.shippingAndHandlingCurrency}`),
            },
            {
                label: t("contents.oi.form.shippingaddressdetails"),
                name: "shippingAddressDetails",
                value: getPlainText(
                    shippingAddress
                        ? `${shippingAddress.address} ${shippingAddress.city} ${shippingAddress.state} ${shippingAddress.zip} ${shippingAddress.country}`
                        : ""
                ),
            },
            {
                label: t("contents.oi.form.totalamount"),
                name: "totalAmount",
                value: getPlainText(`${FormatNumber(tx.totalAmount)} ${tx.totalAmountCurrency}`),
            },
            {
                label: t("contents.oi.form.dateofrefund"),
                name: "dateOfRefund",
                value: getFormatDate(tx.dateOfRefund),
            },
            {
                label: t("contents.oi.form.refundamount"),
                name: "refundAmount",
                value: getPlainText(`${FormatNumber(tx.refundAmount)} ${tx.refundCurrency}`),
            },
            {
                label: t("contents.oi.form.refundprocessed"),
                name: "refundProcessed",
                value: getPlainText(tx.refundProcessed ? t("contents.alerts.yes") : t("contents.alerts.no")),
            },
            {
                label: t("contents.oi.form.avsresultcode"),
                name: "avsResultCode",
                value: getPlainText(tx.avsResultCode),
            },
            {
                label: t("contents.oi.form.avschecked"),
                name: "avsChecked",
                value: getPlainText(tx.avsChecked ? t("contents.alerts.yes") : t("contents.alerts.no")),
            },
            {
                label: t("contents.oi.form.cvvresultcode"),
                name: "cvvResultCode",
                value: getPlainText(tx.cvvResultCode),
            },
            {
                label: t("contents.oi.form.cvvchecked"),
                name: "cvvChecked",
                value: getPlainText(tx.cvvChecked ? t("contents.alerts.yes") : t("contents.alerts.no")),
            },
            {
                label: t("contents.oi.form.threedsecicode"),
                name: "threeDSEciCode",
                value: getPlainText(tx.threeDSEciCode),
            },
            {
                label: t("contents.oi.form.threedsauthenticationvalue"),
                name: "threeDSAuthenticationValue",
                value: getPlainText(tx.threeDSAuthenticationValue),
            },
            {
                label: t("contents.oi.form.devicename"),
                name: "deviceName",
                value: getPlainText(tx.deviceName),
            },
            {
                label: t("contents.oi.form.deviceid"),
                name: "deviceId",
                value: getPlainText(tx.deviceId),
            },
            {
                label: t("contents.oi.form.devicelocation"),
                name: "deviceLocation",
                value: getPlainText(tx.deviceLocation),
            },
            {
                label: t("contents.oi.form.ipaddress"),
                name: "ipAddress",
                value: getPlainText(tx.ipAddress),
            },
            {
                label: t("contents.oi.form.refundpolicy"),
                name: "refundPolicy",
                value: getLink(tx.refundPolicy),
            },
            {
                label: t("contents.oi.form.termsandconditions"),
                name: "termsAndConditions",
                value: getLink(tx.refundPolicy),
            },
        ];
        return showCardInfo(fields);
    };

    const getCustomerInformationForm = () => {
        const customerInfo = {
          ...data.customerInformation,
          ...(data.details?.customerInformation || {})
        };
      
        const fields = [
          {
            label: "Account ID",
            name: "accountId",
            value: getPlainText(customerInfo.accountId)
          },
          {
            label: "Card Number",
            name: "cardNumber",
            value: data.cardBin && data.cardLast4 ? 
              getPlainText(`${data.cardBin}******${data.cardLast4}`) : 
              '-'
          },
      
          {
            label: "Delivery Address",
            name: "deliveryAddress",
            value: getPlainText(data.deliveryAddress?.address1)
          },
      
          {
            label: "Device ID",
            name: "deviceId",
            value: getPlainText(data.device?.deviceId)
          },
          {
            label: "IP Address",
            name: "ipAddress",
            value: getPlainText(data.device?.ipAddress)
          },
          {
            label: "Device Fingerprint",
            name: "deviceFingerprint",
            value: getPlainText(data.device?.deviceFingerprint)
          },
      
          {
            label: "Transaction Date",
            name: "transactionDate",
            value: getFormatDate(data.transactionDate)
          },
          {
            label: "Transaction Amount",
            name: "transactionAmount",
            value: data.amount ? 
              getPlainText(`${FormatNumber(data.amount)} ${data.currency}`) : 
              '-'
          },
      
          {
            label: "Merchant Name",
            name: "merchantName",
            value: getPlainText(data.merchantInformation?.merchantName)
          },
          {
            label: "Merchant Contact",
            name: "merchantContact",
            value: getPlainText(data.merchantInformation?.merchantContactPhone)
          },
          {
            label: "Store Name",
            name: "storeName",
            value: getPlainText(data.merchantInformation?.storeDetails?.storeName)
          },
          {
            label: "Merchant URL",
            name: "merchantUrl",
            value: getLink(data.merchantInformation?.merchantUrl)
          }
        ];
      
        if (data.receipt?.productsPurchasedList?.[0]?.deliveryDetails) {
          const delivery = data.receipt.productsPurchasedList[0].deliveryDetails;
          fields.push(
            {
              label: "Shipping Carrier",
              name: "shippingCarrier",
              value: getPlainText(delivery.shippingCarrier)
            },
            {
              label: "Tracking Number",
              name: "trackingNumber",
              value: getPlainText(delivery.trackingNumber)
            },
            {
              label: "Delivery Status",
              name: "deliveryStatus",
              value: getPlainText(delivery.otherStatusDescription)
            },
            {
              label: "Shipment Date",
              name: "dateOfShipment",
              value: getFormatDate(delivery.dateOfShipment)
            },
            {
              label: "Delivery Date",
              name: "dateOfDelivery",
              value: getFormatDate(delivery.dateOfDelivery)
            }
          );
        }
      
        return fields.length ? showCardInfo(fields) : showCardEmpty();
      };
         
    
      const pastTransactionsColumns = [
        {
          title: "Date of Purchase",
          dataIndex: "dateOfPurchase",
          key: "dateOfPurchase",
          render: date => date ? getFormatDate(date) : '-',
        },
        {
          title: "Amount of Purchase",
          dataIndex: "amountOfPurchase",
          key: "amountOfPurchase",
          align: "right",
          render: amount => amount ? FormatNumber(amount) : '-',
        },
        {
          title: "Credit Card Number",
          dataIndex: "creditCardNumber",
          key: "creditCardNumber",
          render: (_, record) =>
            (record.creditCardBin && record.creditCardFour)
              ? `${record.creditCardBin}******${record.creditCardFour}`
              : '-',
        },
        {
          title: "Recurring Transaction",
          dataIndex: "recurringTransaction",
          key: "recurringTransaction",
          render: value => value || '-',
        },
      ];
      

    const getProductPurchasedTable = () => {
        let dataSource = [];
        if (data.details && data.details.productPurchased) {
            dataSource = data.details.productPurchased;
        }
        const columns = [
            {
                dataIndex: "productName",
                key: "productName",
                title: t("tables.fields.productname"),
            },
            {
                dataIndex: "productDescription",
                key: "productDescription",
                title: t("tables.fields.productdescription"),
            },
            {
                dataIndex: "productUrl",
                key: "productUrl",
                title: t("tables.fields.producturl"),
                render: (productUrl, record) => <a href={productUrl}>{productUrl}</a>,
            },
            {
                dataIndex: "unitPriceAmount",
                key: "unitPriceAmount",
                title: t("tables.fields.unitpriceamount"),
                align: "left",
                width: 150,
                render: (unitPriceAmount, record) => `${FormatNumber(unitPriceAmount)} ${record.unitPriceCurrency}`,
            },
            {
                dataIndex: "quantity",
                key: "quantity",
                title: t("tables.fields.quantity"),
                align: "left",
                width: 80,
            },
        ];

        return <Table columns={columns} rowKey="productName" pagination={false} dataSource={dataSource} />;
    };

    const getIndustryDetailsFlightDetailsTable = () => {
        let dataSource = [];
        if (
            data.details &&
            data.details.productPurchased &&
            data.details.productPurchased[0] &&
            data.details.productPurchased[0].industryDetails &&
            data.details.productPurchased[0].industryDetails.flightDetails
        ) {
            dataSource = data.details.productPurchased[0].industryDetails.flightDetails;
        }

        const columns = [
            {
                dataIndex: "memberRewardsNumber",
                key: "memberRewardsNumber",
                title: t("tables.fields.memberrewardsnumber"),
            },
            {
                dataIndex: "manifest",
                key: "manifest",
                title: t("tables.fields.manifest"),
            },
        ];

        return <Table columns={columns} rowKey="memberRewardsNumber" pagination={false} dataSource={dataSource} />;
    };

    const getIndustryDetailsHotelDetailsTable = () => {
        let dataSource = [];
        if (
            data.details.productPurchased &&
            data.details.productPurchased[0] &&
            data.details.productPurchased[0].industryDetails &&
            data.details.productPurchased[0].industryDetails.hotelDetails
        ) {
            dataSource = data.details.productPurchased[0].industryDetails.hotelDetails;
        }

        const columns = [
            {
                dataIndex: "reservationNumber",
                key: "reservationNumber",
                title: t("tables.fields.reservationnumber"),
            },
            {
                dataIndex: "reservationDate",
                key: "reservationDate",
                title: t("tables.fields.reservationdate"),
                render: (Reservation, record) => getFormatDate(Reservation),
            },
            {
                dataIndex: "checkInDate",
                key: "checkInDate",
                title: t("tables.fields.checkindate"),
                render: (checkInDate, record) => getFormatDate(checkInDate),
            },
            {
                dataIndex: "checkOutDate",
                key: "checkOutDate",
                title: t("tables.fields.checkoutdate"),
                render: (checkOutDate, record) => getFormatDate(checkOutDate),
            },
            {
                dataIndex: "memberRewardsNumber",
                key: "memberRewardsNumber",
                title: t("tables.fields.memberrewardsnumber"),
            },
        ];

        return <Table columns={columns} rowKey="reservationNumber" pagination={false} dataSource={dataSource} />;
    };

    const getPhysicalFulfillmentTable = () => {
        let dataSource = [];
        if (data.details && data.details.deliveryDetails && data.details.deliveryDetails.physicalFulfillment) {
            dataSource = data.details.deliveryDetails.physicalFulfillment;
        }
        const columns = [
            {
                dataIndex: "shippingCarrierUrl",
                key: "shippingCarrierUrl",
                title: t("tables.fields.shippingcarrierurl"),
                render: (shippingCarrierUrl, record) => <a href={shippingCarrierUrl}>{shippingCarrierUrl}</a>,
            },
            {
                dataIndex: "trackingNumber",
                key: "trackingNumber",
                title: t("tables.fields.trackingnumber"),
            },
            {
                dataIndex: "dateOfShipment",
                key: "dateOfShipment",
                title: t("tables.fields.dateofshipment"),
                render: (dateOfShipment, record) => getFormatDate(dateOfShipment),
            },
            {
                dataIndex: "dateOfDelivery",
                key: "dateOfDelivery",
                title: t("tables.fields.dateofdelivery"),
                render: (dateOfDelivery, record) => getFormatDate(dateOfDelivery),
            },
            {
                dataIndex: "signatureConfirmation",
                key: "signatureConfirmation",
                title: t("tables.fields.signatureconfirmation"),
            },
        ];

        return <Table columns={columns} rowKey="shippingCarrierUrl" pagination={false} dataSource={dataSource} />;
    };

    const getDigitalServiceDeliveryTable = () => {
        let dataSource = [];
        if (data.details && data.details.deliveryDetails && data.details.deliveryDetails.digitalServiceDeliver) {
            dataSource = data.details.deliveryDetails.digitalServiceDeliver;
        }

        const columns = [
            {
                dataIndex: "proofOfDelivery",
                key: "proofOfDelivery",
                title: t("tables.fields.proofofdelivery"),
            },
            {
                dataIndex: "proofOfUsage",
                key: "proofOfUsage",
                title: t("tables.fields.proofofusage"),
            },
            {
                dataIndex: "locationOfUsage",
                key: "locationOfUsage",
                title: t("tables.fields.locationofusage"),
            },
            {
                dataIndex: "frequencyOfUsage",
                key: "frequencyOfUsage",
                title: t("tables.fields.frequencyofusage"),
            },
            {
                dataIndex: "notes",
                key: "notes",
                title: t("tables.fields.notes"),
            },
        ];

        return <Table columns={columns} rowKey="proofOfDelivery" pagination={false} dataSource={dataSource} />;
    };

    const getCrmDetailsTable = () => {
        let dataSource = [];
        if (data.details && data.details.crmDetails) {
            dataSource = data.details.crmDetails;
        }

        const columns = [
            {
                dataIndex: "dateOfInteraction",
                key: "dateOfInteraction",
                title: t("tables.fields.dateofinteraction"),
                render: (dateOfInteraction, record) => getFormatDate(dateOfInteraction),
            },
            {
                dataIndex: "contactName",
                key: "contactName",
                title: t("tables.fields.contactname"),
            },
            {
                dataIndex: "notes",
                key: "notes",
                title: t("tables.fields.notes"),
            },
        ];

        return <Table columns={columns} rowKey="dateOfInteraction" pagination={false} dataSource={dataSource} />;
    };

    const getLookupHistoryTable = () => {
        const columns = [
            {
                dataIndex: "_id",
                key: "_id",
                title: t("tables.fields.id"),
            },
            {
                dataIndex: "lookupReference",
                key: "lookupReference",
                title: t("tables.fields.lookupreference"),
            },
            {
                dataIndex: "lookupDate",
                key: "lookupDate",
                title: t("tables.fields.lookupdate"),
                render: (lookupDate, record) => getFormatDate(lookupDate),
            },
        ];

        return <Table columns={columns} rowKey="_id" pagination={false} dataSource={lookup} />;
    };

    const getAttachments = () => {
        const columns = [
            {
                dataIndex: "id",
                key: "id",
                width: 200,
                title: t("contents.oi.form.attachmentsid"),
            },
            {
                dataIndex: "friendlyFileName",
                key: "friendlyFileName",
                title: t("contents.oi.form.attachmentsfile"),
            },
            {
                dataIndex: "data",
                key: "data",
                width: 150,
                title: t("tables.fields.action"),
                render: (data, record) => (
                    <a download={data} href={`data:${record.mimeType};base64,${data}`} target="_blank" rel="noopener noreferrer">
                        {t("tables.actions.download")}
                    </a>
                ),
            },
        ];

        return <Table columns={columns} rowKey="id" pagination={false} dataSource={attachment ? attachment : []} />;
    };

    const showCardEmpty = () => (
        <Card style={{ marginBottom: "1rem" }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Card>
    );

    const showCardInfo = (fields) => (
        <Card style={{ marginBottom: "1rem" }}>
            <Form size="small" {...formItemLayout}>
                <Row gutter={24}>
                    {fields.map((field) => (
                        <Col key={field.name} xs={24} md={12} offset={field.offset} style={{ marginBottom: 12 }}>
                            <span style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "500", marginRight: 6 }}>
                                {field.label}:
                            </span> 
                            {field.value}
                        </Col>
                    ))}
                </Row>
            </Form>
        </Card>
    );

    const showCardTwoBlockInfo = (fields) => (
        <Form size="small" {...formItemLayout}>
            {fields.map((field) => (
                <Row key={field.name}>
                    <Col span={24} style={{ marginBottom: 12 }}>
                        <span style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "500", marginRight: 6 }}>
                            {field.label}:
                        </span> 
                        {field.value}
                    </Col>
                </Row>
            ))}
        </Form>
    );

    if (loading) {
        return (
            <div style={{ textAlign: 'center', padding: '50px' }}>
                <Spin size="large" />
            </div>
        );
    }

    if (!data) {
        return (
            <div style={{ textAlign: 'center', padding: '50px' }}>
                <Empty 
                    description={t("contents.oi.cart_not_found") || "Cart not found"} 
                />
            </div>
        );
    }

    return (
        <div>
            <PageHeader 
                className="site-page-header" 
                title={`${t("contents.oi.cart_details")} (${data.txShieldOICartReference})`}
            />
            <Row gutter={[16, 16]} style={{ marginBottom: "1rem" }}>
                <Col span={12}>
                    <Card style={{ minHeight: 430 }}>
                        {getCartDetailsLeftForm()}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card style={{ minHeight: 430 }}>
                        {getCartDetailsRightForm()}
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="tab-wrap">
                <Col span={24}>
                    <Tabs type="card">
                    <TabPane tab={t("contents.oi.tabs.customer_information")} key="customerInformation">
  {getCustomerInformationForm()}
</TabPane>


<TabPane tab="Past Transactions" key="pastTransactions">
  <Table
    columns={pastTransactionsColumns}
    dataSource={data.details?.pastTransactions || []}
    rowKey="dateOfPurchase"
    pagination={false}
  />
</TabPane>


                        <TabPane tab={t("contents.oi.tabs.lookup_history")} key="lookup">
                            <Table
                                columns={[
                                    {
                                        title: t("tables.fields.id"),
                                        dataIndex: "_id",
                                        key: "_id",
                                    },
                                    {
                                        title: t("tables.fields.lookupreference"),
                                        dataIndex: "lookupReference",
                                        key: "lookupReference",
                                    },
                                    {
                                        title: t("tables.fields.lookupdate"),
                                        dataIndex: "lookupDate",
                                        key: "lookupDate",
                                        render: date => getFormatDate(date),
                                    },
                                ]}
                                dataSource={lookup}
                                rowKey="_id"
                                pagination={false}
                            />
                        </TabPane>

                        <TabPane tab={t("contents.oi.tabs.attachments")} key="attachments">
                            {attachment?.length > 0 ? (
                                <Table
                                    columns={[
                                        {
                                            title: t("contents.oi.form.attachmentsid"),
                                            dataIndex: "id",
                                            key: "id",
                                            width: 200,
                                        },
                                        {
                                            title: t("contents.oi.form.attachmentsfile"),
                                            dataIndex: "friendlyFileName",
                                            key: "friendlyFileName",
                                        },
                                        {
                                            title: t("tables.fields.action"),
                                            dataIndex: "data",
                                            key: "data",
                                            width: 150,
                                            render: (data, record) => (
                                                <a
                                                    download={record.friendlyFileName}
                                                    href={`data:${record.mimeType};base64,${data}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t("tables.actions.download")}
                                                </a>
                                            ),
                                        },
                                    ]}
                                    dataSource={attachment}
                                    rowKey="id"
                                    pagination={false}
                                />
                            ) : (
                                showCardEmpty()
                            )}
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>
    );
};

export default CartDetail;