import React, { useState, useEffect } from "react";
import { THREEDS_URL } from "../../config/config";
import { Modal, Button, Form, Input, Select } from "antd";
import axios from "axios";
import Swal from "sweetalert2";

const { Option } = Select;

const AddBinMidModal = (props) => {
    const { t, fetchBinMid, setIsAddBinMidModalVisible, isAddBinMidModalVisible, siteList } = props;
    const [formData, setFormData] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form] = Form.useForm();
    const initFormData = {
        bin: "",
        mid: "",
        card_type: "Visa",
        active: "",
        sid: null
    };

    const card_type = [
        {name:'Visa', value:'Visa'},
        {name:'Mastercard', value:'Mastercard'},
        {name:'AMEX', value:'AMEX'},
        // {name:'Discover', value:'Discover'},
        // {name:'Diners', value:'Diners'},
        {name:'CUP', value:'CUP'},
        {name:'JCB', value:'JCB'},
        // {name:'Maestro', value:'Maestro'},
    ];

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
    };

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formValInit = () => {
        setFormData(initFormData);
    };

    const handleOk = () => {
        onFinish();
        setIsSubmitting(true);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
        form.setFieldsValue(allV);
    };

    const onFinish = () => {
        const apiUrl = THREEDS_URL + `/api/v1/3ds/binmid/create`;
        axios
            .post(apiUrl, formData, { headers: { Authorization: "Bearer " + localStorage.getItem("threeds_token") } })
            .then(({ data }) => {
                if (data.status.toLowerCase() === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: t("contents.3dsecure.binmid.added"),
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchBinMid();
                            setIsSubmitting(false);
                            form.resetFields();
                        },
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        text: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                    setIsSubmitting(false);
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.error_msg : "",
                    showConfirmButton: true,
                });
                setIsSubmitting(false);
            });
    };

    const isOkButtonProps = () => !formData || !formData.bin || !formData.mid || !formData.sid;

    const handleCancel = () => {
        setIsAddBinMidModalVisible(false);
    };

    return (
        <>
            <Modal
                title={t("contents.3dsecure.create_binmid")}
                width={600}
                visible={isAddBinMidModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={isSubmitting} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {isSubmitting ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form form={form} {...layout} name="new_binmid" onValuesChange={formValChange}>
                    {/* <Alert
                        style={{ marginBottom: 25 }}
                        message={t("contents.3dsecure.form.addbinmidwarning")}
                        type="warning"
                        showIcon
                    /> */}
                    <Form.Item name="bin" label={t("contents.3dsecure.form.bin")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="mid" label={t("contents.3dsecure.form.mid")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="card_type" label={t("contents.3dsecure.form.card_type")}>
                        <Select placeholder={t("contents.3dsecure.form.please_select")}>
                            {card_type
                                ? card_type.map((item) => (
                                        <Option key={item.value} value={item.value}>
                                            {item.name}
                                        </Option>
                                    ))
                                : null}
                        </Select>
                    </Form.Item>
                    <Form.Item name="sid" label={t("contents.3dsecure.form.merchant_name")} rules={[{ required: true }]}>
                        <Select
                                showSearch
                                showArrow="true" 
                                placeholder={t("contents.3dsecure.form.please_select")}
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}>
                            {siteList
                                ? siteList.map((item) => (
                                        <Option key={item.sid} value={item.sid}>
                                            {item.site_name}
                                        </Option>
                                    ))
                                : null}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AddBinMidModal;
