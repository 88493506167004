import React, { useState, useEffect, useCallback } from "react";
// import StatusText from "../../helper/Table/StatusText";  // commented due to unused var
import { Table, PageHeader, Card, Form, Select, Row, Col, Button, Space, Typography } from "antd";
import { SearchOutlined, FileSearchOutlined, PlusOutlined } from "@ant-design/icons";
import ExportModalV3 from "../../helper/ExportModalV3";
import axios from "axios";
import SortOrder from "../../helper/Table/SortOrder";
import { THREEDS_URL } from "../../config/config";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import MergeColumns from "../../helper/Table/MergeColumns";
import AddBinMidModal from "./AddBinMidModal";
import ImportBinMidModal from "./ImportBinMidModal";
import BinMidModal from "./Details/BinMidModal";
import moment from "moment";
import DataFetchHookThreeDS from "../../helper/DataFetchHookThreeDS";
import GroupAccess from "../../helper/GroupAccess";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
// import { ExportOutlined } from "@ant-design/icons"; // commented due to unused var

const DATA_URL = THREEDS_URL + "/api/v1/3ds/binmid";
const SIDS_URL = THREEDS_URL + "/api/v1/merchants";
const DATA_URL_EXPORT = THREEDS_URL + "/api/v1/3ds/binmid/reports";
const { Option } = Select;

const ThreeDSecureBinMid = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, doFetch, setRequestData] = DataFetchHookThreeDS(DATA_URL); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [sortedInfo, setSortedInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [latestTotal, setLatestTotal] = useState(null);
    const [binMid, setBinMid] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isImportModalVisible, setIsImportModalVisible] = useState(false);
    const [isAddBinMidModalVisible, setIsAddBinMidModalVisible] = useState(false);
    const { t } = props;
    const { Text } = Typography;

    const [siteList, setSiteList] = useState([]);

    const initFormData = {
        // field: "merchant",
        // keyword: null,
        sids: [],
    };

    const exportHeaders = [
        { label: "Date Added", key: "bm.date_added" },
        { label: "BIN", key: "bm.bin" },
        { label: "MID", key: "bm.mid" },
        { label: "Card Type", key: "bm.card_type" },
        { label: "Site Name", key: "rs.name" },
        { label: "Status", key: "IF(bm.active,'Active','Inactive')" }
    ];

    const exportHeadersOptional = [];

    const [form] = Form.useForm();

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setSortedInfo(sorter);
    };

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getSiteList = useCallback(() => {
        axios
            .post(
                SIDS_URL,
                {},
                { headers: { Authorization: "Bearer " + localStorage.getItem("threeds_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setSiteList(data.list);
            })
            .catch(() => false);
    }, []);

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);

        if (siteList.length === 0) {
            getSiteList();
        }
    };

    const showBinMid = (binMidId) => {
        setBinMid(binMidId);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let searchData = {};
        if (formData) {
            searchData = {
                // keyword: formData.keyword ? formData.keyword.trim() : null,
                // field: formData.field,
                sids: formData.sids,
            };
        }

        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: null, endDate: null, page: 1 });
    };

    const columns = [
        {
            dataIndex: "id",
            key: "id",
            title: t("tables.fields.id"),
            width: 50,
            sortOrder: SortOrder(sortedInfo, "id"),
        },
        {
            dataIndex: "bin",
            key: "bin",
            title: t("tables.fields.bin"),
            width: 150,
            sorter: {
                compare: (a, b) => String(a.bin).localeCompare(String(b.bin)),
                multiple: 2,
            },
            sortOrder: SortOrder(sortedInfo, "bin"),
        },
        {
            dataIndex: "mid",
            key: "mid",
            align: "left",
            title: t("tables.fields.mid"),
            width: 150,
            sorter: {
                compare: (a, b) => a.mid.localeCompare(b.mid),
                multiple: 2,
            },
            sortOrder: SortOrder(sortedInfo, "mid"),
        },
        {
            dataIndex: "card_type",
            key: "card_type",
            align: "left",
            title: t("tables.fields.card_type"),
            width: 150,
            sorter: {
                compare: (a, b) => a.card_type.localeCompare(b.card_type),
                multiple: 2,
            },
            sortOrder: SortOrder(sortedInfo, "card_type"),
        },
        {
            dataIndex: "sitename",
            key: "sitename",
            align: "left",
            title: t("tables.fields.sitename"),
            width: 150,
            sorter: {
                compare: (a, b) => a.sitename.localeCompare(b.sitename),
                multiple: 2,
            },
            sortOrder: SortOrder(sortedInfo, "sitename"),
        },
        {
            dataIndex: "active",
            key: "active",
            align: "left",
            title: t("tables.fields.status"),
            width: 150,
            render: (item) => (
                parseInt(item) === 1 ?
                    <Text type="success">Active</Text> :
                    <Text type="danger">Inactive</Text>
            )
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showBinMid(record.id)} style={{ marginLeft: "auto" }}>
                        <FileSearchOutlined style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.85)" }} />
                    </Button>
                </Space>
            ),
        },
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 10 }}>
                            <Form.Item name="sids">
                                <Select
                                    showSearch
                                    mode="multiple"
                                    showArrow="true"
                                    maxTagCount="responsive"
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                    placeholder={t("tables.fields.merchant")}
                                >
                                    {siteList
                                        ? siteList.map((item) => (
                                                <Option key={item.sid} value={item.sid}>
                                                    {item.site_name + ` (mid: ` + item.sid + `)`}
                                                </Option>
                                            ))
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="field">
                                <Select style={{ width: "100%" }}>
                                    <Option value="merchant">Merchant Name / ID</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="keyword">
                                <Input placeholder="Keyword" />
                            </Form.Item>
                        </Col> */}
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 4, offset: 4 }} xl={{ span: 3, offset: 7 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    Clear All
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> Search
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );
    
    const showAddBinMidModal = () => {
        setIsAddBinMidModalVisible(true);
    };

    const fetchBinMid = () => {
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: currentPage });
    }

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{false ? t("contents.3dsecure.binmid.title") : "BIN / MID List"}</h3>
            <div style={{marginTop:30}}>
                {
                    GroupAccess("SYSADMIN") ? 
                    <>
                    <Button type="link" onClick={showAddBinMidModal} style={{ marginLeft: "auto" }}>
                        <PlusOutlined /> {t("contents.3dsecure.binmid.new_binmid")}
                    </Button>
                    
                    <ImportBinMidModal
                        t={t}
                        keyname="import_csv"
                        title={t("tables.actions.importbinmid")}
                        style={{ marginLeft: 8 }}
                        setIsImportBinMidModalVisible={setIsImportModalVisible}
                        isImportBinMidModalVisible={isImportModalVisible}
                        fetchBinMid={fetchBinMid}
                        siteList={siteList}
                    /></> 
                : null
                }
                
                <ExportModalV3
                    t={t}
                    keyname="export_all"
                    title={t("tables.actions.export")}
                    export_option={true}
                    data={requestData}
                    data_url={DATA_URL_EXPORT}
                    headers={exportHeaders}
                    fieldHeadersOptional={exportHeadersOptional}
                    filename={`3ds_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                    style={{ marginLeft: 8 }}
                    customToken={ localStorage.getItem("threeds_token") }
                />
            </div>
        </div>
    );

    return (
        <div>
            <PageHeader title={false ? t("contents.3dsecure.binmid.binmidlist") : "BIN / MID"}></PageHeader>
            {AdvancedSearchForm()}
            <AddBinMidModal t={t} fetchBinMid={fetchBinMid} setIsAddBinMidModalVisible={setIsAddBinMidModalVisible} isAddBinMidModalVisible={isAddBinMidModalVisible} siteList={siteList} />
            <BinMidModal t={t} binmidId={binMid} isModalVisible={isModalVisible} handleCancel={handleCancel} siteList={siteList} fetchBinMid={fetchBinMid} />
            <Table
                scroll={{ x: '100%' }}
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="id"
                className="table-v3 custom-pagination custom-pagination-v2"
                dataSource={data.datalist && Array.isArray(data.datalist) ? getDataSource(data.datalist) : []}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </div>
    );
};

export default ThreeDSecureBinMid;